const actions = {
  SEND_OTP_CLICK: 'mobmap_sendotp_clicked',
  MOBILE_LINKED: 'mobMapCampaignNumberExists',
  MOBILE_FRAUD_LISTED: 'mobMapCampaignNumberBanned',
  MOBILE_EDITED: 'mobMapCampaignNumberEntered',
  VERIFY_OTP_CLICK: 'mobmap_verifyotp_clicked',
  VERIFY_SUCCESS: 'mobmap_phone_Verified',
  VERIFY_ERROR: 'mobmap_verify_error',
  VERIFY_EXHAUSTED: 'mobMapCampaignNumberEntryExceed',
  CTA_SUGGESTED_EMAIL: 'mobmapcampaign_shown:SuggestedEmail',
  CTA_SOCIAL: 'mobmapcampaign_clicked:SocialLogin',
  CTA_EMAIL_PASS_SOCIAL: 'mobmapcampaignshown:emailPass',
  CTA_EMAIL_OTP: 'mobmapcampaignshown:emailOtp',
};

export const LOGIN_CODES = {
  SUGGESTED_EMAIL: 1,
  GOOGLE: 2,
  FACEBOOK: 3,
  EMAIL_PASSWORD: 4,
  EMAIL_OTP: 6,
};

export default actions;
