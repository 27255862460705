import React from 'react';
import { Route } from 'react-router-dom';
import LazyLoad from '@nykaa/lazy-load';

import routes from 'routeConfig';

import Footer from './Loadable';

const routesWithFooter: string[] = [];
routes.forEach((route) => {
  if (route.footer) {
    routesWithFooter.push(route.path);
  }
});

function SmartFooter() {
  return (
    <Route
      path={routesWithFooter}
      exact
    >
      <LazyLoad
        placeHolder={<div />}
        bottomMargin={250}
      >
        <Footer />
      </LazyLoad>
    </Route>
  );
}

export default SmartFooter;
