import { getCookie, deleteCookie } from 'shared/helpers/utils/cookie';
import { pushEvent } from 'helpers/utils/dataLayer';
import {
  smartLockEvents,
  FACEBOOK_LOGIN_COOKIE,
  GOOGLE_LOGIN_COOKIE,
  GOOGLE_PROVIDER,
  FACEBOOK_PROVIDER,
  GOOGLE_LOGIN,
} from './constants';


export const smartLockTracking = (event: string) => {
  pushEvent(
    event,
    {},
  );
};

declare global {
  export interface Window {
    FederatedCredential: any;
    gapi: any;
    FB: any;
    PasswordCredential: any;
  }
}

interface UserForStoreCredentials {
  email: string;
  userName: string;
}

export const storeCredentials = (user: UserForStoreCredentials) => {
  const { email, userName } = user || {};

  // * delete fblogin cookie which has domain=.nykaa.com & path=/
  deleteCookie(FACEBOOK_LOGIN_COOKIE);
  deleteCookie(GOOGLE_LOGIN_COOKIE);
  /*
  ! Making sure that requestIdleCallback is available when using smart login feature.
  ! As requestIdleCallback feature is supported in limited browsers.
  */
  const federatedCredential = window.FederatedCredential;
  if (email && userName && federatedCredential && window.requestIdleCallback) {
    window.requestIdleCallback(() => {
      let provider;
      const gCookie = getCookie(GOOGLE_LOGIN_COOKIE);
      const fbCookie = getCookie(FACEBOOK_LOGIN_COOKIE);
      // ! this fbCookie logic is needed because
      // ? the google cookie is controlled by google SDK alone
      // * so checking fbCookie first can prevent false positive for google cookie
      if (fbCookie) {
        provider = FACEBOOK_PROVIDER;
      } else if (gCookie) {
        provider = GOOGLE_PROVIDER;
      }
      switch (provider) {
        case GOOGLE_PROVIDER: {
          const gCred = new federatedCredential({
            id: email,
            name: userName,
            provider: GOOGLE_LOGIN,
          });
          smartLockTracking(smartLockEvents.ATTEMPT_SAVE_CREDENTIALS);
          navigator.credentials.store(gCred);
          break;
        }

        default: break;
      }
    });
  }
};


export const pushSmartLockNotSupported = () => {
  pushEvent(smartLockEvents.SMART_LOCK_NOT_SUPPORTED);
};
