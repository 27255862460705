import { ApiHelper } from '@nykaa/utils/network';
import { logger } from '@nykaa/logger';

import { ROUTES, getUrl } from 'shared/api/urls';
import { transformConfigFlags } from './transformer';

export const getConfigFlags = async () => {
  const url = getUrl(ROUTES.CONFIG_FLAGS);
  try {
    const { data } = await ApiHelper(url, 'get');

    const transformedConfigFlags = transformConfigFlags(data);
    return transformedConfigFlags;
  } catch (error) {
    // ! FIXME: use the new logging solution
    logger.error(error, 'Error fetching config flags');
    return null;
  }
};

export const getBreadCrumbs = async (id: string) => {
  const url = getUrl(ROUTES.BREADCRUMBS);
  const domain = process.env.PLATFORM;
  try {
    const { data } = await ApiHelper(`${url}?id=${id}&domain=${domain}`, 'get');
    return data?.response;
  } catch (error) {
    logger.error(error, 'Error fetching in breadcrumbs');
    return null;
  }
};
