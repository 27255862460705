
const UserPortfolioEvents = {
  PORTFOLIO_QUESTION_FETCHING: 'PORTFOLIO_QUESTION_FAILED',
  PORTFOLIO_QUESTION_SUCCESS: 'PORTFOLIO_QUESTION_SUCCESS',
  PORTFOLIO_QUESTION_FAILURE: 'PORTFOLIO_QUESTION_FETCHING',
  PORTFOLIO_QUESTION_NOT_FOUND: 'PORTFOLIO_QUESTION_NOT_FOUND',
  USER_PORTFOLIO_FETCHING: 'USER_PORTFOLIO_FAILED',
  USER_PORTFOLIO_SUCCESS: 'USER_PORTFOLIO_SUCCESS',
  USER_PORTFOLIO_FAILURE: 'USER_PORTFOLIO_FETCHING',
  USER_PORTFOLIO_NOT_FOUND: 'USER_PORTFOLIO_NOT_FOUND',
  UPDATE_USE_PORTFOLIO: 'USER_PORTFOLIO_UPDATE',
  UPDATE_USE_PORTFOLIO_FETCHING: 'UPDATE_USE_PORTFOLIO_FETCHING',
  UPDATE_USE_PORTFOLIO_SUCCESS: 'UPDATE_USE_PORTFOLIO_SUCCESS',
  UPDATE_USE_PORTFOLIO_FAILURE: 'UPDATE_USE_PORTFOLIO_FAILURE',
};

export default UserPortfolioEvents;
