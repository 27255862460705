import React, { memo, useEffect } from 'react';
import {
  Route,
  Switch,
  RouteComponentProps,
  useLocation,
} from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';

import Header from 'components/Header';
import Footer from 'components/Footer';
// import GDPR from 'components/GDPR';
import UserBucket from 'components/UserBucket';

import statusCodes from 'constants/statusCodes';
import routes from 'routeConfig';
import Boot from 'shared/components/Boot';
import BaseStyles from 'shared/styles/BaseStyles';
import VP from 'shared/components/VisitorPrioritization';
import InitializeExperiment from 'shared/components/Experiment/Initialize';
import InitializeAnalytics from 'shared/components/Analytics/Initialize';
import BackToTop from './BackToTop';

import { getAppTheme } from './theme';
import styled from './styled';
import { setSuperProperty } from 'helpers/utils/user';
import { useSelector } from 'react-redux';
import { State } from 'store/types';
import ChatButton from './ChatButton';
import { AB_ZENDESK_CHAT } from 'constants/product';
import { getEligibleForVariant } from 'helpers/experimentV2';

interface PageRoute {
  path: string;
  component: any;
  routes?: [object];
  status?: number;
  exact?: boolean;
}

const BodyWrapper = styled.div`
  -webkit-font-smoothing: antialiased;
`;

const RoutesWithSubRoutes = (route: PageRoute) => (
  <Route
    path={route.path}
    exact={route.exact}
    render={(props: RouteComponentProps) => {
      if (route.status === statusCodes.NOT_FOUND && props.staticContext) {
        props.staticContext.statusCode = route.status;
      }
      return (
        <route.component
          routes={route.routes} // needed for nested routes
          {...props}
        />
      );
    }}
    fallback={() => <div>Loading... main</div>}
  />
);

const theme = getAppTheme();

const App = () => {
  const isZendeskEnabled = useSelector((state: State) =>
    getEligibleForVariant(state, AB_ZENDESK_CHAT, 'A')
  );
  const { pathname } = useSelector((state: State) => state.appReducer);
  useEffect(() => {
    setSuperProperty();
  }, []);

  let location = useLocation();
  if (pathname && pathname.indexOf('/lp') > 0) {
    location.pathname = pathname;
    if (!__SERVER__) {
      window.history.replaceState({}, '', pathname);
    }
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <BaseStyles />
        <Boot />
        <VP />
        <Header />
        {/* <HeaderPost /> */}
        <BodyWrapper>
          <Switch>
            {routes.map((route, index: number) => (
              <RoutesWithSubRoutes
                key={index}
                {...route}
              />
            ))}
          </Switch>
        </BodyWrapper>
        <Footer />
        {isZendeskEnabled && <ChatButton />}
        <BackToTop />
        {/* <GDPR /> */}
        <UserBucket />
        <InitializeAnalytics />
        <InitializeExperiment />
      </ThemeProvider>
    </>
  );
};

export default memo(App);
