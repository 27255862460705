import React from 'react';
import loadable from '@loadable/component';
import { ProductLoader } from 'components/ProductLoader';


export default loadable(
  () => import(/* webpackChunkName: "product.atf" */ './Product'),
  {
    fallback: <ProductLoader />,
  },
);
