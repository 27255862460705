// libs
import { useEffect, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// helpers
import { initializeExperiment } from '@nykaa/experiment-sdk/client';
import { logger } from 'shared/logging';
import { useUserData } from 'shared/hooks/auth';
import { reInitializeForNykaaUsers } from '@nykaa/experiment-sdk/client/initializeExperiment';

// analytics
import { pushExperiments } from './analytics';

// defs
import { ExperimentConfig, ExperimentAllVariant } from '@nykaa/experiment-sdk/types';
import { State } from 'shared/store/types';


interface ExperimentInfo {
  uuid: string;
  experiments: ExperimentAllVariant;
}

const DEFAULT_CONFIG: ExperimentConfig[] = [];

function Initialize() {
  // get logged in user email
  const { email } = useUserData();
  const dispatch = useDispatch();

  // check if experiments SDK is enabled
  const isExperimentSDKEnabled = useSelector((state: State) => state.remoteConfig.switches?.experimentV2Enabled || false);

  // get experiment config from remote config
  const experimentConfigs = useSelector((state: State) => state.remoteConfig.configs.ABV2 || DEFAULT_CONFIG);

  // function as callback after evaluation
  const onExpEvaluation = (experimentsInfo: ExperimentInfo) => {
    pushExperiments(experimentsInfo);
  };

  useEffect(() => {
    if (isExperimentSDKEnabled === false) {
      return;
    }

    try {
      initializeExperiment(experimentConfigs, onExpEvaluation, dispatch);
    } catch (err) {
      logger.error(err, 'Experiment SDK error');
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExperimentSDKEnabled, experimentConfigs]);

  useEffect(() => {
    if (isExperimentSDKEnabled === false) {
      return;
    }

    if (email) {
      try {
        reInitializeForNykaaUsers(experimentConfigs, onExpEvaluation, email, dispatch);
      } catch (err) {
        logger.error(err, 'Experiment SDK Re-Initialize error');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExperimentSDKEnabled, experimentConfigs, email]);

  return null;
}

export default memo(Initialize);
