import { Action } from 'shared/store/types';
import actionTypes from './actionTypes';
import { transformAnswer } from './transformers';


const defaultState = {
  questions: [],
  answers:  {},
  givenAnswers: {},
  loading: false,
  isQuestionsNotFound: false,
  isAnswersNotFound: false,
  isFetchingAnswersError: false,
  isFetchingQuestionsError: false,
};

const userPortfolio = (
  state = defaultState,
  action: Action,
) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.PORTFOLIO_QUESTION_FETCHING:
      return {
        ...state,
        loading: true,
        isQuestionsNotFound: false,
        isFetchingQuestionsError: false,
      };

    case actionTypes.PORTFOLIO_QUESTION_SUCCESS:
      return {
        ...state,
        questions: payload,
        loading: false,
        isQuestionsNotFound: false,
        isFetchingQuestionsError: false,
      };

      case actionTypes.PORTFOLIO_QUESTION_NOT_FOUND:
        return {
          ...state,
          loading: false,
          isQuestionsNotFound: true,
          isFetchingQuestionsError: false,
        };

      case actionTypes.PORTFOLIO_QUESTION_FAILURE:
        return {
          ...state,
          loading: false,
          isQuestionsNotFound: false,
          isFetchingQuestionsError: true,
        };
      case actionTypes.USER_PORTFOLIO_FETCHING:
        return {
          ...state,
          loading: true,
          isAnswersNotFound: false,
          isFetchingAnswersError: false,
        };

      case actionTypes.USER_PORTFOLIO_SUCCESS:
        return {
          ...state,
          answers: payload,
          givenAnswers: transformAnswer(payload),
          loading: false,
          isAnswersNotFound: !(Object.keys(payload)?.length > 0),
          isFetchingAnswersError: false,
        };

        case actionTypes.USER_PORTFOLIO_NOT_FOUND:
          return {
            ...state,
            loading: false,
            isAnswersNotFound: true,
            isFetchingAnswersError: false,
          };

        case actionTypes.USER_PORTFOLIO_FAILURE:
          return {
            ...state,
            loading: false,
            isAnswersNotFound: true,
            isFetchingAnswersError: true,
          };
        case actionTypes.UPDATE_USE_PORTFOLIO:
          return {
            ...state,
            loading: false,
            isFetchingAnswersError: true,
            answers: payload,
          };
        case actionTypes.UPDATE_USE_PORTFOLIO_SUCCESS:
          return {
            ...state,
            loading: false,
            isAnswersNotFound: !(Object.keys(payload)?.length > 0),
            isFetchingAnswersError: true,
            givenAnswers: payload,
          };
    default:
      return state;
  }
};


export default userPortfolio;
