export const smartLockEvents = {
  SMART_LOCK_SUPPORTED: 'SLSupported',
  SMART_LOCK_NOT_SUPPORTED: 'SLNotSupported',
  CREDENTIALS_FOUND: 'SLCredentialsFound',
  CREDENTIALS_NOT_FOUND: 'SLCredentialsNotFound',
  ATTEMPT_SUCCESS: 'SLAttemptSuccess',
  ATTEMPT_FAIL: 'SLAttemptFail',
  ATTEMPT_SAVE_CREDENTIALS: 'SLSaveCalled',
};

export const GOOGLE_LOGIN = 'https://accounts.google.com';
export const FACEBOOK_LOGIN = 'https://www.facebook.com';

export const FACEBOOK_LOGIN_COOKIE = 'fblogin';
export const GOOGLE_LOGIN_COOKIE = 'googlelogin';

export const FACEBOOK_PROVIDER = 'fb';
export const GOOGLE_PROVIDER = 'google';
export const STATUS_SUCCESS = 'success';

export const credentialTypes = {
  PASSWORD: 'password',
  FEDERATED: 'federated',
};

export const DISABLE_SMART_LOGIN = 'disableSmartLogin';
