export const DEFAULT_LOG_DIR = './logs';

export const LOG_LEVEL = {
  info: 0,
  debug: 1,
  error: 2,
};
export const LOG_LEVEL_COLORS = {
  info: 'green',
  debug: 'blue',
  error: 'red',
};
export const DATE_PATTERN_FOR_LOG_FILE = 'yyyy-MM-ddTHH';
export const CHAT_HELP_TEXT = 'How may we help you';

export const APPLICATION_FOLDER_PATH = '/application.log';
