import { styled } from '@nykaa/ui-components';
import React, { useState } from 'react';
import ZendeskWidget from 'shared/components/Zendesk';
import { CHAT_HELP_TEXT } from 'logging/constants';

const Wrapper = styled.div`
  display: flex;
  background-color: #fff !important;
  width: 290px;
  height: 45px;
  box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%);
  bottom: 0;
  right: 20px;
  position: fixed;
  z-index: 9;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: ${({ theme }) => theme.borders.radius10};
  ${({ theme }) => theme.typography.bodyMedium};
  cursor: pointer;
  transition: all 200ms ease-in-out;
  transform: none;
  opacity: 1;
`;

const ChatIcon = styled.div`
  width: 26px;
  height: 24px;
  display: inline-block;
  background: url('https://www.nykaa.com//assets/desktop/images/chat_logo.svg')
    no-repeat 0 0;
  margin-top: 11px;
  margin-left: 15px;
  position: relative;
`;

const Title = styled.div`
  width: 160px;
  font-size: 16px;
  margin-top: 11px;
  margin-left: 15px;
`;

const ChatButton = () => {
  const [showZendeskChat, setShowZendeskChat] = useState(false);
  return (
    <>
      {showZendeskChat ? (
        <ZendeskWidget setShowZendeskChat={setShowZendeskChat} />
      ) : (
        <Wrapper onClick={() => setShowZendeskChat(true)}>
          <ChatIcon />
          <Title>{CHAT_HELP_TEXT}</Title>
        </Wrapper>
      )}
    </>
  );
};

export default ChatButton;
