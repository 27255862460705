const appEvents = {
  APP_REDUCER_SET_PAGE: 'app/setPage',
  APP_REDUCER_STATUS_CODE: 'app/statusCode',
  CONFIG_FLAGS: 'app/configFlags',
  SET_PATHNAME: 'app/setPathname',
  SET_RUN: 'app/setRun',
  SET_BREADCRUMB: 'app/setBreadCrumb',
  TOGGLE_SLIDER: 'app/toggleSlider',
};

export default appEvents;
