export const LOCAL_STORE_FIRST_VISIT_KEY = 'isFirstVisit';

export const INDIA_ISO_CODE = 'IN';

export const NEPAL_ISO_CODE = 'NP';

export const SRILANKA_ISO_CODE = 'LK';

export const countriesMetaData = [
  {
    id: '0',
    name: 'India',
    flagIcon: 'india',
    value: INDIA_ISO_CODE,
  },
  {
    id: '1',
    name: 'Srilanka',
    flagIcon: 'srilanka',
    value: SRILANKA_ISO_CODE,
  },
  {
    id: '2',
    name: 'Nepal',
    flagIcon: 'nepal',
    value: NEPAL_ISO_CODE,
  },
];

export const COUNTRY_CODE_KEY = 'countryCode';

export const InternationalReturnPolicyInfo = 'Returns/replacement available for defective or damaged products.Refer our returns section for details/exclusions.';

export const INT_MODE_ENABLED = 'intModeEnabled';

export const LOCAL_FIRST_VISIT = 'isFirstVisit';

export const INTERNATIONAL_FREE_SHIPPING = 5000;

