import { Action } from 'shared/store/types';
import { ProState } from './types';
import actionTypes from './actionTypes';


const defaultState: ProState = {
  loadingFirstFoldWidgets: false,
  firstFoldWidgetsError: false,
  loadingSecondFoldWidgets: false,
  secondFoldWidgetsError: false,
};

const Pro = (state = defaultState, action: Action): ProState => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.FETCH_INTRO_WIDGETS_PROGRESS:
      return {
        ...state,
        firstFoldWidgetsError: false,
        loadingFirstFoldWidgets: true,
      };

    case actionTypes.FETCH_INTRO_WIDGETS_SUCCESS:
      return {
        ...state,
        loadingFirstFoldWidgets: false,
        firstFoldWidgets: payload,
      };

    case actionTypes.FETCH_INTRO_WIDGETS_ERROR:
      return {
        ...state,
        loadingFirstFoldWidgets: false,
        firstFoldWidgetsError: true,
      };

    case actionTypes.FETCH_SECOND_FOLD_WIDGETS_START:
      return {
        ...state,
        loadingSecondFoldWidgets: true,
      };

    case actionTypes.FETCH_SECOND_FOLD_WIDGETS_SUCCESS:
      return {
        ...state,
        loadingSecondFoldWidgets: false,
        secondFoldWidgets: payload,
      };

    case actionTypes.FETCH_SECOND_FOLD_WIDGETS_ERROR:
      return {
        ...state,
        loadingSecondFoldWidgets: false,
        secondFoldWidgetsError: true,
      };

    default:
      return state;
  }
};

export default Pro;
