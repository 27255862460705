const productEvents = {
  FETCH_PRODUCT_DATA_PROGRESS: 'product/fetch_data_progress',
  FETCH_PRODUCT_DATA_SUCCESS: 'product/fetch_data_success',
  FETCH_PRODUCT_DATA_NOT_FOUND: 'product/fetch_data_not_found',
  FETCH_PRODUCT_DATA_FAILED: 'product/fetch_data_failed',
  CHANGE_PRODUCT_VARIANT: 'product/change_variant',
  PRODUCT_LIKE_REVIEW: 'product/like_review',
  FETCH_OFFERS_PROGRESS: 'product/fetch_product_offers_progress',
  FETCH_OFFERS_SUCCESS: 'product/fetch_product_offers_success',
  FETCH_OFFERS_FAILED: 'product/fetch_product_offers_failed',
  BANNERS_SUCCESS: 'product/banners_success',
  PRODUCT_UNMOUNT: 'product/unmount',
  PRODUCT_REVIEW_ACTIVE_TAB: 'product/review_active_tab',
};
export default productEvents;
