import actionTypes from './actionTypes';
import { VPState, Action } from './types';


const defaultState: VPState = {
  error: false,
  configs: {},
  message: '',
  scriptLoaded: false,
};


const VPReducer = (
  state = defaultState,
  action: Action,
) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.VP_BLOCK_ERROR:
      return {
        ...state,
        error: payload?.flag,
        message: payload?.message || '',
      };

    case actionTypes.VP_DATA_CHANGED:
      return {
        ...state,
        configs: payload || {},
      };

    default:
      return state;
  }
};

export default VPReducer;
