import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@nykaa/ui-components';
import { loadVerloop, trackUser } from 'store/auth/helper';
import setUpSessionCount from 'helpers/utils/sessionCount';
import { addAutoSignInEvent } from 'shared/store/smartLock/autoSignin';
import { storeCredentials } from 'store/smartLock/helper';
import { RequestConfig } from '@nykaa/utils/network';
import { handleStoreId } from 'helpers/store';
import { handleDevice } from 'helpers/deviceIdentity';
import { ConfigFlags } from 'store/app/types';
import {
  getAuthSteps,
  deleteAuthSteps,
  getMobileNumberLocal,
  setLoginLocation,
} from 'shared/helpers/auth';
import { LOGIN_LOCATION, MOBILE_VERIFICATION_URL } from 'shared/constants/auth';
import { pushMobileVerificationEvents } from 'shared/User/helpers/mobileVerification';
import trackingEvent, {
  LOGIN_CODES,
} from 'shared/User/constants/mobileVerification/trackingEvents';
import { pushData } from '@nykaa/data-layer/utils';
import { useDispatch, useSelector } from 'react-redux';
import appEvents from 'store/app/actionTypes';
import { State } from 'store/types';
import { getEligibleForVariant } from 'helpers/experimentV2';
import { AB_ZENDESK_CHAT } from 'constants/product';

const FrameWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.textDisabled};
  transform: translate(-50%, -50%);
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 11;
  border: none;
`;

const Iframe = styled.iframe`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  overflow: hidden;
  max-width: 500px;
  width: 492px;
  min-height: 400px;
  position: fixed;
  border: none;
  border-radius: ${({ theme }) => theme.borders.radius30};
`;

interface BootProps {
  configFlags: ConfigFlags;
  configFlagsFetched: boolean;
  fetchAuth: () => Promise<void>;
  email: string;
  fetchConfigFlags: () => Promise<void>;
  firstName: string;
  formKey: string;
  id: string;
  dataFetched: boolean;
  fetchedOverNetwork: boolean;
  userName: string;
  storeId: string;
  isPro: boolean;
  groupId: string;
  isStoreEnabled: boolean;
  trackDeviceId: boolean;
  suggestMobile: () => Promise<any>;
  isSuggestedMobileMap: boolean;
  getUserHash: () => Promise<any>;
  bucketId: string;
  isPricesRevealedActive: boolean;
}

const Boot = ({
  configFlags,
  configFlagsFetched,
  fetchAuth,
  email,
  fetchConfigFlags,
  firstName,
  formKey,
  id,
  isPro,
  groupId,
  dataFetched,
  userName,
  fetchedOverNetwork,
  storeId,
  isStoreEnabled,
  trackDeviceId,
  suggestMobile,
  isSuggestedMobileMap,
  getUserHash,
  bucketId,
  isPricesRevealedActive,
}: BootProps) => {
  const [showIframe, setShowIframe] = useState(false);
  const isZendeskEnabled = useSelector((state: State) =>
    getEligibleForVariant(state, AB_ZENDESK_CHAT, 'A')
  );
  const [iframeUrl, setIframeUrl] = useState('');
  useEffect(() => {
    if (isStoreEnabled) {
      handleStoreId(storeId);
    }
  }, [storeId, isStoreEnabled]);

  useEffect(() => {
    setUpSessionCount();
  }, []);

  useEffect(() => {
    window.requestIdleCallback(() => {
      fetchAuth();
      fetchConfigFlags();
    });
  }, [fetchAuth, fetchConfigFlags]);

  useEffect(() => {
    if (dataFetched && formKey) {
      RequestConfig.setFormKey(formKey);
    }
  }, [dataFetched, formKey]);

  useEffect(() => {
    const userData = {
      email,
      firstName,
      id,
      isPro,
      groupId,
    };
    if (dataFetched) {
      trackUser(userData);
      if (trackDeviceId) {
        handleDevice();
      }
    }
  }, [
    email,
    firstName,
    id,
    dataFetched,
    userName,
    fetchedOverNetwork,
    trackDeviceId,
    groupId,
    isPro,
  ]);

  const handleAutoSignIn = useCallback(() => {
    pushData({ phfill: getMobileNumberLocal() ? 1 : 0 });
    setLoginLocation(LOGIN_LOCATION.MYACCOUNT_LOGIN_BTN);
    if (configFlags.isSmartLockActive) {
      if (!email) {
        addAutoSignInEvent();
      } else if (fetchedOverNetwork) {
        storeCredentials({ email, userName });
      }
    }
  }, [email, fetchedOverNetwork, userName, configFlags]);

  const suggestMobileNumber = useCallback(() => {
    suggestMobile().then((res) => {
      const authMapSteps = getAuthSteps();
      deleteAuthSteps();
      if (res?.isEligible) {
        let action = trackingEvent.CTA_SUGGESTED_EMAIL;
        if (authMapSteps) {
          if (authMapSteps == LOGIN_CODES.EMAIL_PASSWORD) {
            action = trackingEvent.CTA_EMAIL_PASS_SOCIAL;
          }
          if (authMapSteps === LOGIN_CODES.EMAIL_OTP) {
            action = trackingEvent.CTA_EMAIL_OTP;
          } else if (authMapSteps > 1) {
            action = trackingEvent.CTA_SOCIAL;
          }
        }
        pushMobileVerificationEvents({ actionName: action, data: res });
        const encodedUrl = encodeURIComponent(
          `${window.location.pathname}${window.location.search}`
        );
        const url = `${MOBILE_VERIFICATION_URL}?redirect=${encodedUrl}`;
        setTimeout(() => {
          setShowIframe(true);
          setIframeUrl(url);
        }, 500);
      }
    });
  }, [suggestMobile]);

  useEffect(() => {
    if (dataFetched && configFlagsFetched) {
      handleAutoSignIn();
    }
  }, [configFlagsFetched, dataFetched, handleAutoSignIn]);

  useEffect(() => {
    if (!dataFetched) {
      setShowIframe(false);
      return;
    }
    const authMapSteps = getAuthSteps();
    if (authMapSteps === LOGIN_CODES.GOOGLE) {
      pushData({ googleSuccess: true });
    }
    if (
      isSuggestedMobileMap &&
      dataFetched &&
      authMapSteps &&
      email &&
      window.location?.pathname?.indexOf(MOBILE_VERIFICATION_URL) < 0
    ) {
      suggestMobileNumber();
    } else {
      deleteAuthSteps();
    }
    // 1 suggested email flow
    // 2 login source google
    // 3 facebook
  }, [dataFetched, suggestMobileNumber, isSuggestedMobileMap, email]);

  useEffect(() => {
    if (isPricesRevealedActive && bucketId) {
      pushData({ bucketId });
    }
  }, [bucketId, isPricesRevealedActive]);

  useEffect(() => {
    if (!dataFetched) {
      return;
    }
    if (!isZendeskEnabled) {
      if (id) {
        getUserHash();
        return;
      }
      loadVerloop();
    }
  }, [dataFetched, getUserHash, id, isZendeskEnabled]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: appEvents.SET_PATHNAME });
  });

  if (showIframe && dataFetched) {
    return (
      <FrameWrapper id="mobmapping_frame">
        <Iframe
          src={iframeUrl}
          allowFullScreen
        />
      </FrameWrapper>
    );
  }
  return <></>;
};

export default Boot;
