import { connect } from 'react-redux';
import { State } from 'store/types';

import { fetchAuth, getUserHash } from 'shared/store/auth/action';
import { fetchConfigFlags } from 'shared/store/app/actions';
import { getMobileMappingStatus } from 'User/store/mobileVerification/actions/mobileMappingStatus';

import View from './View';


const mapDispatchToProps = {
  fetchAuth,
  fetchConfigFlags,
  suggestMobile: getMobileMappingStatus,
  getUserHash,
};

const mapStateToProps = (state: State) => {
  const {
    auth: { user, dataFetched, fetchedOverNetwork },
    appReducer: {
      storeId,
      configFlagsFetched,
      configFlags,
    },
    remoteConfig,
  } = state;

  return {
    configFlags,
    configFlagsFetched,
    email: user.email,
    firstName: user.firstName,
    userName: user.userName,
    id: user.id,
    dataFetched,
    fetchedOverNetwork,
    formKey: user.formKey,
    groupId: user.groupId,
    isPro: user.isPro,
    storeId,
    isStoreEnabled: remoteConfig.configs.storeOptions.status,
    trackDeviceId: remoteConfig.switches.trackDeviceId,
    bucketId: remoteConfig.configs.priceReveal?.data?.bucket || '',
    isPricesRevealedActive: remoteConfig.configs.priceReveal?.active,
    isSuggestedMobileMap: remoteConfig.switches?.suggestMobileMap,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(View);
