import {
  DEFAULT_PROTO,
  DEFAULT_DOMAIN,
  customHeaders,
  DEFAULT_DOMAIN_MEN,
} from 'shared/constants/requestHeaders';
import { State } from 'store/types';
import statusCodes from 'constants/statusCodes';
import { DEFAULT_LOG_DIR } from 'shared/logging/constants';
import { platformNames } from 'shared/constants';

export const getStatusCode = (state: State) =>
  state.appReducer?.statusCode || statusCodes.SUCCESS;

const getUrlWithoutQueryString = (str: string) => {
  try {
    let occurrence = str.indexOf('?');
    let urlWithoutQueryString = '';
    if (occurrence != -1) {
      urlWithoutQueryString = str.substring(0, occurrence);
    } else {
      urlWithoutQueryString = str;
    }
    return urlWithoutQueryString;
  } catch (e) {
    return '';
  }
};

const getDefaultPlatform = () =>
  __PLATFORM__ === platformNames.MEN ? DEFAULT_DOMAIN_MEN : DEFAULT_DOMAIN;

const getHostFromRequestHeaders = (requestHeaders: any): string =>
  requestHeaders[customHeaders.DOMAIN_NAME] || getDefaultPlatform();

const getRequestProtocol = (requestHeaders: any): string =>
  requestHeaders[customHeaders.FORWARDED_PROTOTYPE] || DEFAULT_PROTO;

const canonicalBuilder = (url: string, req: any): string => {
  if (!url || !req) {
    return '';
  }
  return `${getRequestProtocol(req.headers)}://${getHostFromRequestHeaders(
    req.headers
  )}${url}`;
};

export const getCanonicalFromRequest = (req: any): string => {
  if (!req) {
    return '';
  }
  try {
    const browserUrl = req.header(customHeaders.BROWSER_URL);
    let canonicalUrl = '';
    const canonicalUrlFromHeader = req.header(customHeaders.CANONICAL_URL);
    const xOriginalUrl = req.header(customHeaders.ORIGINAL_URL);
    const urlWithoutParams = req.originalUrl.split('?');
    if (canonicalUrlFromHeader) {
      canonicalUrl = canonicalUrlFromHeader;
    } else if (xOriginalUrl) {
      canonicalUrl = getUrlWithoutQueryString(xOriginalUrl);
    } else if (browserUrl) {
      canonicalUrl = browserUrl.split('?')[0];
    } else {
      canonicalUrl = canonicalBuilder(urlWithoutParams[0], req);
    }
    return canonicalUrl;
  } catch (error) {
    //TODO: log error
    return '';
  }
};

export const isNonDev = (): boolean =>
  process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'production';

export const getLogDir = () => process.env.LOG_DIR || DEFAULT_LOG_DIR;

export const checkNewrelicClient = (sampleRate: number) => {
  const randomSample = Math.random();
  if (sampleRate > 0 && sampleRate >= randomSample) {
    return true;
  }
  return false;
};
