import { ravenInstance } from './raven';


const getLogger = () => {
  if (__SERVER__ ) {
    const ServerLogger = require('./server');
    return ServerLogger.default;
  } else {
    return ravenInstance;
  }
};

export const logger = getLogger();
