import { Action } from 'shared/Nps/store/types';
import actionTypes from './actionTypes';
import { ReviewState } from './types';


const defaultState: ReviewState = {
  reviews: [],
  isReviewFetched: false,
  reviewsLoading: false,
  selectedReview: {},
  reviewFailure: false,
  isReviewSubmitted: false,
};

const reviewReducer = (state = defaultState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.FETCH_REVIEWS_START:
      return {
        ...state,
        reviewsLoading: true,
      };
    case actionTypes.FETCH_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: payload,
        isReviewFetched: true,
        reviewsLoading: false,
      };
    case actionTypes.FETCH_REVIEWS_ERROR:
      return {
        ...state,
        isReviewFetched: false,
        reviewsLoading: false,
        reviewFailure: true,
      };
    case actionTypes.CURRENT_REVIEW:
      return {
        ...state,
        selectedReview: payload,
        isReviewFetched: true,
        reviewsLoading: false,
      };
    case actionTypes.UPDATE_REVIEWS: {
      let currentReviews = [...state.reviews];
      let reviewIndex = currentReviews && (currentReviews.findIndex((review) =>
          (review.childId?.toString() === payload.childId)
        ));

      if (reviewIndex > -1) {
        currentReviews[reviewIndex] = payload;
      } else {
        const updatedReview = [...currentReviews, payload];
        currentReviews = [...updatedReview];
      }
      return {
        ...state,
        reviews: [...currentReviews],
        isReviewFetched: true,
        reviewsLoading: false,
        isReviewSubmitted: true,
      };
    };
    case actionTypes.UPDATE_REVIEW_IMAGES:
    return {
      ...state,
      selectedReview: { ...state.selectedReview, images: payload?.images, childId: payload?.childId },
    };
    default:
      return state;
  }
};

export default reviewReducer;
