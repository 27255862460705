import { VerifyMappingOtpResponse } from './../../types/mobileVerification/index';
import { ApiHelper } from '@nykaa/utils/network';
import { getAPIHost } from 'shared/api/urls';
import {
  MOBILE_MAPPING_STATUS,
  SEND_OTP,
  VERIFY_OTP,
} from 'User/constants/mobileVerification/url';
import {
  MobileMappingResponse,
  SendMappingOtpRequestParams,
  SendMappingOTPResponse,
} from 'User/types/mobileVerification';
import {
  SEND_MAPPING_OTP_API_ERROR,
  GET_MOBILE_MAPPING_STATUS_ERROR,
  VERIFY_MAPPING_OTP_API_ERROR,
} from 'User/store/mobileVerification/constants/errorMessages';
import { logErrors } from 'helpers/utils/logErrors';

import { transformMobileMappingStatus } from './transformers/mobileMappingStatus';
import { transformSendMappingOtp } from './transformers/sendMappingOtp';
import { transformVerifyMappingOtp } from './transformers/verifyMappingOtp';


export const fetchMobileMappingStats = async (): Promise<MobileMappingResponse> => {
  try {
    const host = getAPIHost() || '';
    const url = `${host}${MOBILE_MAPPING_STATUS}`;

    const formData = new FormData();
    formData.append('source', 'react');

    const { data } = await ApiHelper(url, 'POST', formData);

    const transformedResponse = transformMobileMappingStatus(null, data);
    return transformedResponse;
  } catch (error) {
    const customErrorMessage = GET_MOBILE_MAPPING_STATUS_ERROR;
    logErrors(error, customErrorMessage);

    return transformMobileMappingStatus(error, null);
  }
};


export const sendMappingOtp = async (params: SendMappingOtpRequestParams): Promise<SendMappingOTPResponse> => {
  try {
    const host = getAPIHost() || '';
    const url = `${host}${SEND_OTP}`;
    const formData = new FormData();
    formData.append('source', 'react');

    const {
      mobileNumber,
      isNew,
    } = params;

    if (isNew) {
      formData.append('new_mobile_number', `${mobileNumber}`);
    } else {
      formData.append('mobile_number', `${mobileNumber}`);
    }

    const { data } = await ApiHelper(url, 'POST', formData);
    // transform response;
    const transformedResponse = transformSendMappingOtp(null, data);

    return transformedResponse;
  } catch (error) {
    // Handle Network Error
    const customMessage = SEND_MAPPING_OTP_API_ERROR;
    logErrors(error, customMessage);

    const transformedResponse = transformSendMappingOtp(error, null);
    return transformedResponse;
  }
};


export const verifyMappingOtp = async (params: UserDefinedObject): Promise<VerifyMappingOtpResponse> => {
  try {
    const host = getAPIHost() || '';
    const url = `${host}${VERIFY_OTP}`;
    const {
      mobileNumber,
      isNew,
      otp,
    } = params;

    const formData = new FormData();
    formData.append('source', 'react');
    formData.append('otp', otp);

    if (isNew) {
      formData.append('new_mobile_number', mobileNumber);
    } else {
      formData.append('mobile_number', mobileNumber);
    }

    const { data } = await ApiHelper(url, 'POST', formData);
    const transformedResponse = transformVerifyMappingOtp(null, data);

    return transformedResponse;
  } catch (error) {
    const customMessage = VERIFY_MAPPING_OTP_API_ERROR;
    logErrors(error, customMessage);

    const transformedResponse = transformVerifyMappingOtp(error, null);
    return transformedResponse;
  }
};
