import { logger } from '@nykaa/logger';
import { googleAPI } from '../service';
import { smartLockTracking } from '../helper';
import { smartLockEvents, STATUS_SUCCESS } from '../constants';


interface AuthResponse {
  id_token: string;
}

interface BasicProfile {
  getId(): string;
  getGivenName(): string;
  getFamilyName(): string;
  getEmail(): string;
}

interface GoogleUser {
  getAuthResponse(includeAuthorizationData?: boolean): AuthResponse;
  getBasicProfile(): BasicProfile;
}

const handleGoogleUser = (
  googleUser: GoogleUser,
  resolve: (value?: any) => void,
  reject: (reason?: any) => void
  ) => {
  const authResponse = googleUser.getAuthResponse();

  const profile = googleUser.getBasicProfile();

  const firstName = profile.getGivenName();
  const lastName = profile.getFamilyName();
  const email = profile.getEmail();
  const googleConnectId = profile.getId();
  const googleData = {
    firstName,
    lastName,
    email,
    googleConnectId,
    idToken: authResponse.id_token,
  };
  googleAPI(googleData)
    .then((res) => {
      const { status, message } = res;
      if ((status === STATUS_SUCCESS)) {
        smartLockTracking(smartLockEvents.ATTEMPT_SUCCESS);
        resolve();
      } else {
        reject(new Error(message));
      }
    })
    .catch((e) => {
      logger.error(e, 'ERROR in Google Login');
      reject(e);
    });
};

const AUTH = 'auth2';
export const gSignIn = (id: string) => new Promise((resolve, reject) => {
  const { googleAppId } = process.env;
  try {
    if (window.gapi) {
      // ? if "gapi" is not present we can assume the SDK has not loaded
      window.gapi.load(AUTH, () => {
        const auth2 = window.gapi.auth2.init({
          client_id: googleAppId,
        });
        if (auth2.isSignedIn.get()) {
          const googleUser = auth2.currentUser.get();
          if (googleUser.getBasicProfile().getEmail() === id) {
            handleGoogleUser(googleUser, resolve, reject);
          }
        }
        return auth2.signIn({
          login_hint: id || '',
        })
          .then((googleUser: GoogleUser) => handleGoogleUser(googleUser, resolve, reject))
          .catch((error: Error) => {
            smartLockTracking(smartLockEvents.ATTEMPT_FAIL);
            logger.error(error, 'ERROR in Google LOGIN');
            reject(new Error('ERROR:: Google LOGIN'));
          });
      });
    }
    const sdkNotLoaded = new Error('Google SDK not loaded');
    logger.error(sdkNotLoaded);
    return Promise.reject(sdkNotLoaded);
  }
  catch (error) {
    logger.error(error, 'Google Sign in');
    throw error;
  }
});
