// libs
import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

// helpers
import AnalyticsSDK from '@nykaa/analytics-sdk';
import { useUserData } from 'shared/hooks/auth';
import { getDeviceType, getEnvironment } from './helpers';
import { State } from 'shared/store/types';
import { getEventsUrl } from 'api/urls';
import { pushData } from 'helpers/utils/dataLayer';
import { checkIsIndianUser } from 'helpers/utils/user';
import { DWEB } from 'store/home/constants';
import { logCustomRetinaError } from '@nykaa/analytics-utils/helpers/logger';
import { logger } from '@nykaa/logger';

declare global {
  interface Window {
    na: any;
  }
}

function Initialize() {
  const { id } = useUserData();
  const regionCountry = useSelector(
    (state: State) => state.appReducer?.region?.regionHeaderCountry
  );
  // check if experiments SDK is enabled
  const isAnalyticsV2Enabled =
    useSelector(
      (state: State) => state.remoteConfig.switches?.analyticsV2Enabled || false
    ) && checkIsIndianUser(regionCountry);
  const analyticsDelayTimer = useSelector(
    (state: State) => state.remoteConfig.configs?.analyticsDelayTimer || 1000
  );
  const analyticsExcludedEventNames = useSelector(
    (state: State) =>
      state.remoteConfig.configs?.analyticsExcludedEventNames || []
  );
  const analyticsEventThreshold = useSelector(
    (state: State) => state.remoteConfig.configs?.analyticsEventThreshold || 20
  );
  const appVersion = useSelector((state: State) => state.appReducer.appVersion);
  const analyticsLoggingSampleRate = useSelector(
    (state: State) =>
      state.remoteConfig.configs?.analyticsLoggingSampleRate || 0.1
  );
  const maxExpiryTimeInHour = useSelector(
    (state: State) =>
      state.remoteConfig.configs?.analyticsSessionMaxDurationInHours || 12
  );
  const expiryTimeInMin = useSelector(
    (state: State) =>
      state.remoteConfig.configs?.analyticsSessionExpireInMinutes || 30
  );
  const analyticsIncrementalTimerInMs = useSelector(
    (state: State) =>
      state.remoteConfig.configs?.analyticsIncrementalTimerInMs || 0
  );
  const analyticsBatchMigrationLogic = useSelector(
    (state: State) =>
      state.remoteConfig.switches?.analyticsBatchMigrationLogic || false
  );
  const analyticsBatchEventPayloadSizeLimitInKB = useSelector(
    (state: State) =>
      state.remoteConfig.configs?.analyticsBatchEventPayloadSizeLimitInKB || 0
  );
  const logError = (error: string, customErrorMsg: string) => {
    logger.error(error, customErrorMsg);
  };
  const customAnalyticsSdkLogger = (error: string, customErrorMsg: string) => {
    logCustomRetinaError(
      logError,
      analyticsLoggingSampleRate,
      error,
      customErrorMsg
    );
  };
  const SdkConfigs = {
    config: {
      session: {
        maxExpiryTimeInHour,
        expiryTimeInMin,
      },
      thresholds: {
        batchIntervalInMs: analyticsDelayTimer,
        eventsThresholdCount: analyticsEventThreshold,
      },
      blacklistedEventList: analyticsExcludedEventNames,
      incrementalTimerInMs: analyticsIncrementalTimerInMs,
      eventBatchPayloadSizeLimitInKB: analyticsBatchEventPayloadSizeLimitInKB,
    },
    switches: {
      isBatchingMigrationActive: analyticsBatchMigrationLogic,
    },
  };

  useEffect(() => {
    if (!isAnalyticsV2Enabled) {
      return;
    }

    const sdk = new AnalyticsSDK({
      analyticsEndpoint: getEventsUrl(),
      environment: getEnvironment(),
      vertical: 'nykaa',
      platform: getDeviceType(),
      appVersion: __VERSION__ || '',
      retinaSdkPlatform: DWEB,
      customAnalyticsSdkLogger,
      SdkConfigs,
    });

    pushData({
      appVersion: __VERSION__ || '',
      analyticsSDKVersion: sdk?.version,
    });

    window.na = sdk;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAnalyticsV2Enabled,
    appVersion,
    analyticsDelayTimer,
    analyticsExcludedEventNames,
    analyticsEventThreshold,
    analyticsLoggingSampleRate,
  ]);

  useEffect(() => {
    if (id && window.na) {
      window.na.setCommonFields({ customerId: id });
    }
  }, [id]);

  return null;
}

export default memo(Initialize);
