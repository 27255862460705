import FingerPrintJs from 'fingerprintjs2';
import { logger } from '@nykaa/logger';
import { postDeviceIdentity } from 'store/deviceIdentity/service';


const SEED_FOR_HASH = 31;

export const handleDevice = () => {
  window.requestIdleCallback(() => {
    FingerPrintJs.getPromise()
      .then((components) => {
        const deviceInfoString = JSON.stringify(components);
        const hashedInfo = FingerPrintJs.x64hash128(deviceInfoString, SEED_FOR_HASH);
        return postDeviceIdentity(hashedInfo);
      })
      .catch(err => {
        logger.error(err, 'handleDevice: error generating fingerprint');
      });
  });
};
