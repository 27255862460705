import { logger } from '@nykaa/logger';
import { doLogin, getAuth } from 'shared/store/auth/service';
import { smartLockTracking } from '../helper';
import { smartLockEvents, STATUS_SUCCESS } from '../constants';
import { CredentialParams } from './';


export const emailSignIn = (cred: CredentialParams) => {
  if (!cred.password) {
    return Promise.reject(new Error('password prop doesn\'t exist, this is Chrome < 60'));
  }
  const { id, password } = cred;
  const loginRequestApi = {
    id,
    password,
    platform: 'web',
    smart: '1',
  };
  return doLogin(loginRequestApi).then((data) => {
    if (data[STATUS_SUCCESS]) {
      smartLockTracking(smartLockEvents.ATTEMPT_SUCCESS);
      return getAuth();
    } else if (data.networkError) {
      const networkError = new Error('Network error');
      logger.error(networkError, 'emailSignIn: doLogin');
      throw networkError;
    } else {
      const apiError = new Error('Login Api Error');
      logger.error(apiError, 'emailSignIn: doLogin');
      throw apiError;
    };
  }).catch((e) => {
    smartLockTracking(smartLockEvents.ATTEMPT_FAIL);
    return Promise.reject(e);
  });
};
