enum PageTypes {
  PRODUCT = 'product',
  CATEGORY = 'category',
  SEARCH_SUGGESTION = 'searchSuggestions',
  LISTING = 'listing',
  HOME = 'home',
  SEARCH_LISTING = 'searchListing',
  FITCODE = 'fitcode',
  WISHLIST = 'wishlist',
}

export default PageTypes;
