import {
  SendMappingOTPError,
  SendMappingOTPStatus,
  SendMappingOTPResponse,
} from 'User/types/mobileVerification';
import { statusMessages } from 'shared/constants/statusCodes';
import {
  SEND_MAPPING_OTP_API_ERROR,
  GET_MOBILE_MAPPING_STATUS_ERROR,
  prefixForMobileMappingErrorLogs,
} from 'User/store/mobileVerification/constants/errorMessages';
import { NETWORK_ERROR } from 'shared/constants/toastMessages';


export const transformSendMappingOtp = (error: (null | Error), apiResponse: any): SendMappingOTPResponse => {
  if (error) {
    /**
     * HANDLE NETWORK ERROR
     * In this case we prompt the user that some Network error has happened
     */
    return {
      error: {
        abortFlow: false,
        errorToLog: `${error.response?.status} ${prefixForMobileMappingErrorLogs.NETWORK_ERROR}:${GET_MOBILE_MAPPING_STATUS_ERROR}`,
        messageToDisplay: NETWORK_ERROR, // GENERIC NETWORK ERROR MESSAGE
        type: SendMappingOTPError.NETWORK_ERROR,
      },
    };
  }

  const { status, response } = apiResponse;

  if (status === statusMessages.SUCCESS) {
    /**
     * This covers send OTP success cases
     * as well as some OTP related errors like
     * - Could not send OTP. Please retry after sometime
     * - Retry limit exceeded. Please try again after 5 mins!
     */
    const {
      otp_length: otpLength,
      otp_status: otpStatus,
      retry_left: retriesLeft,
      message,
    } = response;

    if (otpStatus === statusMessages.SUCCESS) {
      // When OTP is sent
      return {
        otpLength,
        retriesLeft,
        message,
        status: SendMappingOTPStatus.SUCCESS,
      };
    }

    // When otpStatus !success
    let errorPrefix = prefixForMobileMappingErrorLogs.UNKNOWN;
    if (retriesLeft === 0) {
      errorPrefix = prefixForMobileMappingErrorLogs.RETRIES_EXHAUSTED;
    }

    return {
      error: {
        errorToLog: `${errorPrefix}:${SEND_MAPPING_OTP_API_ERROR}`,
        messageToDisplay: message,
        abortFlow: false,
        type: SendMappingOTPError.RETRIES_EXHAUSTED,
      },
    };
    // return {
    //   retriesLeft,
    //   message,
    //   status: (retriesLeft === 0) ? SendMappingOTPStatus.RETRIES_EXHAUSTED : SendMappingOTPStatus.OTHERS,
    // };
  }

  /**
   * The following case handles - Errors in User/Mobile number validation
   * Authentication failed
   * Invalid Login Method
   * Suspicious - new mobile exceeds rate limit
   * Entered mobile is in fraud list
   * Mobile Already mapped with a different account
   * Retry count exceeded
   */
  const {
    editingAllowed,
    error: errorCode,
    message,
    status: responseStatus,
  } = response || {};

  let prefixForErrorToLog = '';
  let abortFlow = false;
  let errorType: SendMappingOTPError = SendMappingOTPError.OTHER_ERROR;

  switch (true) {
    case errorCode === 1006:
      // If attempts to enter new mobile increases, Rate Limiter check
      prefixForErrorToLog = prefixForMobileMappingErrorLogs.SUSPICIOUS;
      errorType = SendMappingOTPError.RATE_LIMIT;
      break;

    case responseStatus === '8':
      // Send otp request with the new mobile number but invalid login method
      prefixForErrorToLog = prefixForMobileMappingErrorLogs.INVALID_LOGIN_METHOD;
      abortFlow = true;
      break;

    case responseStatus === '7':
      // If entered number is in fraud the list
      prefixForErrorToLog = prefixForMobileMappingErrorLogs.FRAUD_LIST;
      errorType = SendMappingOTPError.FRAUD_LISTED;
      break;

    case responseStatus === '4':
      // If entered number is already linked/mapped with another customer
      prefixForErrorToLog = prefixForMobileMappingErrorLogs.ALREADY_REGISTERED;
      errorType = SendMappingOTPError.NUMBER_ALREADY_REGISTERED;
      break;

    default:
      // Authentication Failed or any other error
      prefixForErrorToLog = prefixForMobileMappingErrorLogs.AUTHENTICATION_FAILED;
      abortFlow = true;
      break;
  }

  return {
    error: {
      errorToLog: `${prefixForErrorToLog}: ${SEND_MAPPING_OTP_API_ERROR}`,
      messageToDisplay: message,
      abortFlow,
      type: errorType,
    },
    isEditable: !!(editingAllowed),
  };
};
