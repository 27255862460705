import React from 'react';
import { Route } from 'react-router-dom';

import routes from 'routeConfig';
import Header from './Loadable';

const routesWithHeader: string[] = [];
routes.forEach((route) => {
  if (route.header) {
    routesWithHeader.push(route.path);
  }
});

function SmartHeader() {
  return (
    <Route
      path={routesWithHeader}
      exact
    >
      <Header />
    </Route>
  );
}

export default SmartHeader;
