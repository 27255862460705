export enum MobileVerificationStepName {
  LOADING= 'loading',
  LANDING= 'landing',
  VERIFY_OTP= 'verifyOtp',
  SUCCESS = 'success',
  ERROR = 'error',
  EXHAUSTED_VERIFICATION = 'exhaustedVerification',
}


export interface MobileVerificationStep {
  stepName: MobileVerificationStepName;
  error?: string;
  isEditable?: boolean;
  mobileNumber?: number;
  sendOtpExhausted?: boolean;
  otpLength?: number;
}


export interface MobileVerificationState {
  steps: MobileVerificationStep[];
  suggestedMobileNumber?: string,
  flowDisabled: boolean;
}


export interface ErrorInApi {
  abortFlow: boolean;
  messageToDisplay?: string;
  errorToLog: string;
}


export enum GetMappingStatusError {
  INVALID_TOKEN = 'invalidToken',
  NETWORK_ERROR = 'networkError',
  OTHER_ERROR = 'otherErrors',
  GUEST_USER = 'guestUser',
  NOT_ELIGIBLE = 'notEligible'
}


export interface ErrorInGetMappingStatusApi extends ErrorInApi {
  type: GetMappingStatusError;
  takeToHome?: boolean;
}


export enum MobileMappingStatus {
  GUEST_USER = '0',
  VERIFIED = '1',
  MULTIPLE_OR_NO_MOBILE = '2',
  SUGGEST_MOBILE_NUMBER = '3',
  UN_VERIFIED_MOBILE = '5',
  ALREADY_LINKED = '4',
  ERROR = 'error',
}


export interface MobileMappingResponse {
  error?: ErrorInGetMappingStatusApi;
  status?: MobileMappingStatus;
  mobileNumber?: number;
  isEditable?: boolean;
  isEligible?: boolean;
}


// * SEND MOBILE MAPPING OTP
export enum SendMappingOTPError {
  INVALID_TOKEN = 'invalidToken',
  FRAUD_LISTED = 'numberInFraudList',
  INVALID_LOGIN_METHOD = 'invalidLoginMethod',
  NUMBER_ALREADY_REGISTERED = 'numberAlreadyRegistered',
  NETWORK_ERROR = 'networkError',
  RATE_LIMIT = 'suspiciousOrRateLimited',
  OTHER_ERROR = 'otherErrors',
  RETRIES_EXHAUSTED = 'retriesExhausted',
}


export interface SendMappingOtpErrorInApi extends ErrorInApi {
  type: SendMappingOTPError;
}


export enum SendMappingOTPStatus {
  SUCCESS = 'success',
  RETRIES_EXHAUSTED = 'retriesExhausted',
  OTHERS = 'other',
}


export interface SendMappingOTPResponse {
  error?: SendMappingOtpErrorInApi;
  status?: SendMappingOTPStatus;
  isEditable?: boolean;
  message?: string;
  otpLength?: number;
  retriesLeft?: number;
}


export enum VerifyMappingOtpStatus {
  SUCCESS = 'success',
  RETRIES_EXHAUSTED = 'retriesExhausted',
  ERROR = 'error',
}

export enum VerifyMappingOtpError {
  AUTHENTICATION_FAILED = 'authenticationFailed',
  FRAUD_LISTED = 'numberInFraudList',
  NUMBER_ALREADY_LINKED = 'numberLinkedToOtherAccount',
  VERIFICATION_FAILED = 'otpVerificationFailed',
  EXHAUSTED_VERIFY_ATTEMPTS = 'verifyTriesExceeded',
  NETWORK_ERROR = 'networkError',
  OTHER = 'otherError',
}

export interface VerifyMappingOtpErrorInApi extends ErrorInApi {
  type: VerifyMappingOtpError;
}

export interface VerifyMappingOtpResponse {
  error?: VerifyMappingOtpErrorInApi;
  status?: VerifyMappingOtpStatus;
  message?: string;
}


export interface SendMappingOtpRequestParams {
  mobileNumber: number;
  isNew?: boolean;
}
