const statusCodes = {
  SUCCESS: 200,
  NOT_FOUND: 404,
  ERROR: 500,
};

export const statusMessages = {
  SUCCESS: 'success',
};

export default statusCodes;
