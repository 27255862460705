import {
  getABExperimentVariant,
  getABExperimentVariants,
} from '@nykaa/experiment-sdk/store/selectors';

import { State } from '../../../shared/store/types';

export const getVariantsForPageType = (
  state: State,
  pageType: string = ''
): {} => {
  const experimentV2Enabled =
    state.remoteConfig.switches?.experimentV2Enabled || false;
  const landingPageABConfig =
    state.remoteConfig.configs?.landingPageABConfig || {};
  let variants: { [key: string]: string } = {};
  if (
    experimentV2Enabled &&
    landingPageABConfig &&
    landingPageABConfig[pageType]
  ) {
    let experiments = landingPageABConfig[pageType];
    for (let idx = 0; idx < experiments?.length; idx++) {
      const experimentId = experiments[idx];
      const experimentValue = getABExperimentVariants(state, experimentId);
      if (
        experimentValue &&
        experimentValue.isEligible &&
        experimentValue.variant
      ) {
        variants[experimentId] = experimentValue.variant;
      }
    }
  }
  return variants;
};

export const getAllVariants = (state: State): {} => {
  const experimentV2Enabled =
    state.remoteConfig.switches?.experimentV2Enabled || false;
  const experiments = state.remoteConfig.configs?.ABV2 || {};
  let variants: { [key: string]: string } = {};

  if (experimentV2Enabled) {
    for (let idx = 0; idx < experiments?.length; idx++) {
      const experimentId = experiments[idx].id;
      const experimentValue = getABExperimentVariants(state, experimentId);
      if (
        experimentValue &&
        experimentValue.isEligible &&
        experimentValue.variant
      ) {
        variants[experimentId] = experimentValue.variant;
      }
    }
  }

  return variants;
};

export const getEligibleForVariant = (
  state: State,
  id: string,
  experimentVariant: string
) => {
  const experimentV2Enabled =
    state.remoteConfig.switches?.experimentV2Enabled || false;
  const { isEligible, variant } = getABExperimentVariant(state, id);

  if (experimentV2Enabled && isEligible && variant === experimentVariant) {
    return true;
  }

  return false;
};

export const getEligibleVariant = (state: State, id: string) => {
  const enabledExperiment = getABExperimentVariant(state, id) || {};
  return enabledExperiment;
};

export const getEligibleVariantName = (state: State, id: string): string => {
  const experimentV2Enabled =
    state.remoteConfig?.switches?.experimentV2Enabled || false;
  const { isEligible, variant = '' } = getABExperimentVariant(state, id);
  if (experimentV2Enabled && isEligible) {
    return variant;
  }
  return '';
};
