import { RemoteConfig } from './types';
import { FREE_SHIPPING } from 'constants/footerLinks';
import { DEFAULT_META } from 'constants/meta';

export const CACHE_KEY = 'REMOTE_CONFIG';

export const GET_REMOTE_CONFIG_URL = () => process.env.REMOTE_CONFIG_URL;

export const DEFAULT_CONFIG: RemoteConfig = {
  switches: {
    newRatingForm: true,
    appLinkEnabled: true,
    showDeliveryOnPd: true,
    showOffersOnPdp: false,
    showRewardPoints: true,
    showViewSimilar: true,
    internationalMode: false,
    redirectionList: [
      {
        name: 'NewPDP',
        status: true,
        userPercentage: 100,
        cookieName: 'NEW_PDP',
      },
      {
        name: 'NewHome',
        status: true,
        userPercentage: 5,
        cookieName: 'N_HOM',
      },
    ],
    trackDeviceId: false,
    pdPageBanners: false,
    offerListingEnabled: false,
    mobileNumberMapping: false,
    showUserPortfolio: false,
    webOtpAutoRead: false,
    experimentV2Enabled: false,
    analyticsV2Enabled: false,
    analyticsFetchEnabled: false,
    analyticsLoggingEnabled: false,
    analyticsBatchMigrationLogic: false,
    loadInterFont: false,
    dwebPdpCustomVideo: false,
    personalizationEnabled: false,
    isPaginationEnabled: false,
    enableReactConfigOnAddToCart: false,
    seoFooterCategoryEnable: false,
  },
  configs: {
    customersAlsoBought: {
      status: true,
      algorithm: 'coccurence_direct',
    },
    customersAlsoViewed: {
      status: true,
      algorithm: 'coccurence_simple',
    },
    defaultShippingMessage:
      'A shipping charge of Rs.500 will be applied on orders below Rs.5,000 for delivery outside India.',
    pincodeApiDisabled: false,
    rewardPointsOnSignUp: 2000,
    upiOffer: {
      status: false,
      message: '',
    },
    priceReveal: {
      active: false,
      productEligibilityActive: false,
    },
    maxSearchHistoryCount: 5,
    trendingSearchesToShow: 5,
    storeOptions: {
      status: false,
      backToBeautyText: 'Back to Main Store',
      exploreMoreStores: '',
      stores: [],
      rememberLastStore: false,
      userPercentage: 0,
    },
    contentPolicy: {
      userPercentage: 5,
      headerKey: 'Content-Security-Policy-Report-Only',
      status: false,
      value: '',
    },
    sortForListing: [
      {
        name: 'popularity',
        key: 'popularity',
      },
      {
        name: 'discount',
        key: 'discount',
      },
      {
        name: 'name',
        key: 'name',
      },
      {
        name: 'customer top rated',
        key: 'customer_top_rated',
      },
      {
        name: 'new arrivals',
        key: 'new_arrival',
      },
      {
        name: 'price: high to low',
        key: 'price_desc',
      },
      {
        name: 'price: low to high',
        key: 'price_asc',
      },
    ],
    freeShipping: FREE_SHIPPING,
    homePage: {
      initialInventories: 2,
      eagerLoadImages: 2,
      pageTypeSuffix: { status: false },
    },
    meta: DEFAULT_META,
    guidedSearch: {
      status: false,
      userPercentage: 0,
    },
    searchSuggestionIcons: {
      query: 'https://images-static.nykaa.com/media/wysiwyg/2020/search.svg',
      buying_guide:
        'https://images-static.nykaa.com/media/wysiwyg/2020/buying-guide.svg',
      history: 'https://images-static.nykaa.com/media/wysiwyg/2020/clock.svg',
      trending:
        'https://images-static.nykaa.com/media/wysiwyg/2020/ic-trending.svg',
    },
    AB: {
      searchSuggestion: {
        status: false,
        userPercentage: 0,
        keyName: 'ab_newautocomplete_screen',
      },
      guidedSearch: {
        status: false,
        userPercentage: 0,
        keyName: 'ab_guides',
      },
      personalizationHome: {
        status: true,
        userPercentage: 0,
        keyName: 'ab_personalHome',
      },
    },
    ABV2: [],
    analyticsDelayTimer: 1000,
    analyticsExcludedEventNames: [],
    analyticsEventThreshold: 20,
    analyticsLoggingSampleRate: 0.1,
    analyticsSessionMaxDurationInHours: 12,
    analyticsSessionExpireInMinutes: 30,
    analyticsIncrementalTimerInMs: 0,
    analyticsBatchEventPayloadSizeLimitInKB: 0,
    plpPriceReveal: {
      status: true,
      categoryId: [],
      text: 'SALE OFFER INSIDE',
      textColor: '#D7006C',
      badgeColor: '#E6D3DC',
    },
    listingPriceReveal: {
      isActive: false,
      title: 'Hot Pink Sale Price',
      styles: {},
    },
    dnPaginationConfig: {
      bufferSize: 3,
      pageLimit: 10,
    },
    dynamicPriceRangeToastConfig: {
      enabled: true,
      timer: 4,
    },
  },
};
