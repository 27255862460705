import { Action } from 'shared/Nps/store/types.ts';
import statusCodes from 'constants/statusCodes';
import actionTypes from './actionTypes';


export const defaultState = {
  npsToken: '',
  parsedData: [],
  statusCode: statusCodes.SUCCESS,
  customerId: 0,
};

const app = (state = defaultState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.APP_REDUCER_NPS_TOKEN:
      return {
        ...state,
        npsToken: payload,
      };

    default:
      return state;
  }
};

export default app;
