import Raven from 'raven-js';

class RavenSetup {
  private static ravenInstance = false;

  public constructor() {
    if (RavenSetup.ravenInstance) {
      return this;
    } else {
      const { SENTRY_DSN } = process.env;
      this.setting = SENTRY_DSN;
      this.install({});
    }
  }

  private setting = '';

  private install = (options) => {
    if (RavenSetup.ravenInstance) {
      return this;
    }
    options = options || {};
    Raven.config(this.setting, options).install();
    RavenSetup.ravenInstance = true;
  };

  private unInstall = () => {
    Raven.config(this.setting).uninstall();
  };

  public info = (exc: Error, message: string = '') => {
    Raven.captureException(exc, {
      level: 'info',
      extra: {
        message,
      },
    });
  };

  public error = (
    exc: Error | string,
    message: string = '',
    options: any = {}
  ) => {
    Raven.captureException(exc, {
      level: 'error',
      extra: {
        message,
        ...options,
      },
    });
  };
}

export const ravenInstance = new RavenSetup();
