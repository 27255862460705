import { mobileVerificationActions } from './actionTypes';
import { MobileVerificationState } from 'User/types/mobileVerification';
import { Action } from 'shared/store/types';
import initialState from './constants/initialState';


const defaultState: MobileVerificationState = initialState;

const mobileVerification = (state = defaultState, action: Action): MobileVerificationState => {
  const { type, payload } = action;
  const { steps } = state;
  const positionOfInterest = payload?.positionOfInterest;
  let stepsOfInterest = steps;
  if (
    (typeof positionOfInterest === 'number')
    && (positionOfInterest >= 0)
  ) {
    stepsOfInterest = steps.slice(0, positionOfInterest + 1);
  }

  switch (type) {
    case mobileVerificationActions.PUSH:
      return {
        ...state,
        steps: [
          ...stepsOfInterest,
          payload.stepData,
        ],
      };

    case mobileVerificationActions.REPLACE: {
      const stepsToConsiderWhileReplace = stepsOfInterest.slice(0, -1);
      return {
        ...state,
        steps: [
          ...stepsToConsiderWhileReplace,
          payload.stepData,
        ],
      };
    }

    case mobileVerificationActions.SET_SUGGESTED_MOBILE:
      return {
        ...state,
        suggestedMobileNumber: payload,
      };

    case mobileVerificationActions.DISABLE_FLOW:
      return {
        ...state,
        flowDisabled: true,
      };

    default:
      return state;
  }
};

export default mobileVerification;
