import { QuestionData, QuestionRawData, QuestionOption } from 'shared/User/types/userPortfolio/questionData';
import WidgetTypes from 'shared/User/constants/userPortfolio/widgetTypes';
import { getSelectedValues } from 'shared/User/helpers/userPortfolio';


const transformOptions = (options: QuestionOption[], type: string) => {
  if (type === WidgetTypes.SINGLE_IMAGE) {
    let updatedOptions: QuestionOption[] = [];
    options.forEach((option) => {
      const transformOption = {
        value: option.value,
        image: option.image,
        category: option.category,
        color: option.color,
        keyIndex: option.order ? option.order : 1,
        optionId: option.optionId,
      };
      updatedOptions.push(transformOption);
    });
    return updatedOptions;
  }
  return options;
};

export const transformQuestionsList = (questions: QuestionRawData) => {
  if (!(Array.isArray(questions))) {
    return [];
  }

  const transformedQuestions: QuestionData[] = [];
  questions.forEach((questionData) => {
      const transformedQuestion = {
        questionId: questionData.id,
        questionText: questionData.question,
        attribute: questionData.attribute,
        type: questionData.type,
        isSkippable: questionData.metaData?.skippable,
        isPrivate: questionData.metaData?.isPrivate,
        displayText: questionData.metaData?.displayText,
        options: transformOptions(questionData.options, questionData.type),
      };

      transformedQuestions.push(transformedQuestion);
  });

  return transformedQuestions;
};


export const transformAnswersList = (answers: UserDefinedObject) => {
  if (!answers) {
    return {};
  }
  const ansKeys = Object.keys(answers);
  const transformedAnswers: UserDefinedObject = {};

  if (ansKeys?.length > 0) {
    ansKeys.forEach((answer) => {
      if (answers[answer]?.attributes) {
        transformedAnswers[answer] = {
          attributes: answers[answer]?.attributes,
        };
      } else {
        transformedAnswers[answer] = answers[answer];
      }
    });
  }
  return transformedAnswers;
};

export const transformAnswer = (answers: UserDefinedObject) => {
  const givenAnswers: UserDefinedObject = {};
  const answerKeys = Object.keys(answers);
  if (answerKeys.length > 0) {
    answerKeys.forEach((answer) => {
    let ansData = answers[answer];
    const ansType = typeof(answers[answer]);
    if (ansType === 'object' && answer !== 'skinTone') {
      ansData = getSelectedValues(ansData.attributes);
    } else if (answer === 'skinTone') {
      ansData = ansData.attributes[0]?.optionId;
    }
    givenAnswers[answer] = ansData;
    });
  }
  return givenAnswers;
};
