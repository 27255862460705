import { MetaData } from 'store/remoteConfig/types';
import { platformNames } from 'shared/constants';

const PD_META_TITLE =
  'Product_Name: Buy Product_Name Online at Best Price in India | Nykaa';
const PD_META_DESCRIPTION =
  'Product_Name Online: Buy Product_Name at best price from Nykaa. Check out Product_Name review. ✓COD ✓Free Shipping';
const BP_META_TITLE =
  'Brand_Name: Buy Brand_Name Products Online at Best Price in India';
const BP_META_DESCRIPTION =
  'Buy Brand_Name products at best price from Nykaa. Shop for 100% authentic & genuine Brand_Name products. ✓COD ✓Free Shipping ✓Easy Returns ✓Verified Reviews';

export const DEFAULT_META: MetaData = {
  productPage: {
    title: PD_META_TITLE,
    description: PD_META_DESCRIPTION,
  },
  brandPage: {
    title: BP_META_TITLE,
    description: BP_META_DESCRIPTION,
  },
  searchPage: {
    title:
      'Buy Cosmetics Products & Beauty Products Online in India at Best Price | Nykaa',
    description:
      'Buy cosmetics &amp; beauty products online from Nykaa, the online shopping beauty store. Browse makeup, health products &amp; more from top beauty brands. ✔ free shipping* ✔ Cash on Delivery',
    keywords:
      'Nykaa, Nyka, Nykaa.com, Nika, Nyyka, Nyka.com, Nykaa online, Nykaa India, buy cosmetics online, cosmetics online, buy beauty products online, beauty products online, buy cosmetics india, buy beauty products india',
    canonical: 'http://www.nykaa.com/search/result',
  },
};

export const favicon = {
  [platformNames.NYKAA]:
    '//images-static.naikaa.com/media/wysiwyg/uiTools/2024-3/beauty.png',
  [platformNames.MEN]: '//images-static.nykaa.com/media/favicon_man.png',
};
