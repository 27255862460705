import { logger } from '@nykaa/logger';
import { setCookie, getCookie } from 'shared/helpers/utils/cookie';
import { DISABLE_SMART_LOGIN } from 'shared/store/smartLock/constants';
import { removeAuthFromStorage } from 'store/auth/helper';

import {
  GOOGLE_LOGIN,
  smartLockEvents,
  credentialTypes,
} from '../constants';
import { smartLockTracking, pushSmartLockNotSupported } from '../helper';
import { emailSignIn } from './emailSignIn';
import { gSignIn } from './googleSignIn';


// FIXME: This type is redundant- can Use (FederatedCredential|PasswordCredential|PublicKeyCredential|null) types
export interface CredentialParams {
  type: string;
  password: string;
  id: string;
  provider: string;
}

const invalidFederatedCred = new Error('Unknown federated credentials provider');


const autoSignIn = (isExternal = false) => {
  if (window.PasswordCredential && window.FederatedCredential) {
    smartLockTracking(smartLockEvents.SMART_LOCK_SUPPORTED);
    const isFeatureDisabled = getCookie(DISABLE_SMART_LOGIN);
    if (isFeatureDisabled && isExternal) {
      return;
    }

    return navigator.credentials.get({
      password: true,
      federated: {
        providers: [GOOGLE_LOGIN],
      },
      unmediated: true,
    })
      .then((cred: CredentialParams) => {
        if (cred) {
          smartLockTracking(smartLockEvents.CREDENTIALS_FOUND);
          switch (cred.type) {
            case credentialTypes.PASSWORD:
              return emailSignIn(cred);
            case credentialTypes.FEDERATED:
              removeAuthFromStorage();
              switch (cred.provider) {
                case GOOGLE_LOGIN:
                  return gSignIn(cred.id);

                default:
                  logger.error(invalidFederatedCred);
                  return Promise.reject(invalidFederatedCred);
              }

            default:
              return Promise.reject('Unknown credentials type');
          }
        } else {
          smartLockTracking(smartLockEvents.CREDENTIALS_NOT_FOUND);
          return Promise.reject('Credentials Not Found/ User cancelled auto-signin');
        }
      })
      .then(() => {
        location.reload();
      })
      .catch((errorOrReason: any) => {
        if (!isExternal) {
          throw errorOrReason;
        } else {
          setCookie(DISABLE_SMART_LOGIN, true, 1);
        }
      });
  }

  pushSmartLockNotSupported();
  if (isExternal) {
    return;
  }
  return Promise.reject(new Error('Browser doesn\'t support smart lock'));
};

export default autoSignIn;


export const addAutoSignInEvent = () => {
  if (window.NYKAA_WEB_INTERFACE) {
    window.NYKAA_WEB_INTERFACE.autoSignInCallback = autoSignIn;
  }
};
