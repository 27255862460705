export default {
  FETCH_AUTH_DATA_PROGRESS: 'auth/fetch_data_progress',
  FETCH_AUTH_DATA_SUCCESS: 'auth/fetch_data_success',
  FETCH_AUTH_DATA_FAILED: 'auth/fetch_data_failed',
  AUTH_CART_COUNT: 'auth/set_cart_count',
  AUTH_WISHLIST_ADD: 'auth/add_to_wishlist',
  AUTH_WISHLIST_REMOVE: 'auth/remove_from_wishlist',
  LOGOUT: 'auth/logout',
  LOGOUT_ALL: 'auth/logoutFromAllSession',
};
