import { History, Location } from 'history';
import { OFFER_ROOT } from 'constants/queryParams';
import { pages } from 'constants/urls';
import PageTypes from 'constants/pageTypes';

interface QueryParamsObject {
  [key: string]: string;
}

export const queryStringFromParams = (params: object) => {
  if (!params) {
    return '';
  }

  if (typeof params !== 'object' || Array.isArray(params)) {
    throw new Error(`Expected "params" to be an "object" got ${params}`);
  }

  let queryString = '';

  const keys = Object.keys(params);

  if (!(keys.length > 0)) {
    return queryString;
  }

  queryString = '?';
  const queryStringPairs: string[] = [];
  keys.sort().forEach((key: string) => {
    queryStringPairs.push(`${key}=${encodeURIComponent(params[key])}`);
  });

  return `${queryString}${queryStringPairs.join('&')}`;
};

export const queryStringObjectFromUrl = (url: string = '') => {
  const queryString = url.split('?')[1];
  const params = new URLSearchParams(queryString);
  let result: QueryParamsObject = {};
  params.forEach((val, key) => {
    result[key] = val;
  });
  return result;
};

interface UpdatedUrlProps {
  url: string;
  slug?: string;
  parentId?: string;
  childId?: string;
  source?: string;
}

export const getUpdatedUrl = ({
  url,
  slug,
  parentId,
  childId,
  source,
}: UpdatedUrlProps) => {
  if (slug) {
    url = url.replace('{slug}', slug);
  }
  if (parentId) {
    url = url.replace('{id}', parentId);
  }
  if (childId) {
    url = url.replace('{childId}', childId);
  }
  if (source) {
    url = url.replace('{trackingSource}', source);
  }

  return url;
};

export const getUrlWithParamsWithoutHost = (url = '', params) => {
  if (!url) {
    return '';
  }
  try {
    const hostIndex = url.indexOf('.com/');
    if (hostIndex > -1) {
      url = url.substr(hostIndex + 5);
    }
    if (params) {
      const paramSeparator = url.indexOf('?') > -1 ? '&' : '?';
      url += paramSeparator + params;
    }
  } catch (error) {
    throw error;
  }
  return url;
};

export const appendQueryStringToUrl = (
  url: string,
  queryString: string = ''
) => {
  if (!url) {
    throw new Error('"url" can not be empty');
  }

  const queryStringExists = url.indexOf('?') > -1;
  const modifiedUrl = `${url}${queryStringExists ? '&' : '?'}${queryString}`;

  return modifiedUrl;
};

interface RecommendationUrlProps {
  slug: string;
  childId?: string;
  root: string;
}

export const getRecommendationsUrl = ({
  slug,
  childId,
  root,
}: RecommendationUrlProps) => {
  const queryString = `root=${root}${childId ? `&skuId=${childId}` : ''}`;
  const productUrl = `/${appendQueryStringToUrl(slug, queryString)}`;

  return productUrl;
};

export const getListProductUrl = ({ slug, id, parentId, position }) => {
  let queryString = `productId=${parentId}`;
  if (parentId !== id) {
    queryString += `&skuId=${id}`;
  }
  if (position) {
    queryString += `&pps=${position}`;
  }
  const productUrl = `/${appendQueryStringToUrl(slug, queryString)}`;
  return productUrl;
};

export const getQueryObjectFromUrl = (url: string) => {
  if (!url || !(url.length > 1) || !(url.indexOf('?') >= 0)) {
    // * > 1 because we expect it to contain a "?"
    throw new Error('"url" cannot be empty');
  }

  const queryObject: { [key: string]: any } = {};

  const queryString = url.split('?')[1];
  const parameters = queryString.split('&');

  parameters.forEach((parameter: string) => {
    const [key, value] = parameter.split('=');

    if (key && value) {
      // TODO: handle case when value is false||0
      queryObject[key] = value;
    }
  });

  return queryObject;
};

export const getOfferListingUrl = (offerId: number, rootParam: string) =>
  `${pages.OFFER_PRODUCTS}/${offerId}?${OFFER_ROOT}=${rootParam}`;

export function addParamInURL(
  history: History | History<unknown> | string[],
  location: Location<unknown>,
  urlString: string
) {
  const pathName = location.pathname.substr(0, location.pathname.length);
  history.push(`${pathName}${urlString}`);
}

export const getPageType = (pathName: string) => {
  let currentPageType = '';
  if (pathName === '/') {
    currentPageType = PageTypes.HOME;
  }
  if (pathName && pathName.indexOf('/p/') > -1) {
    currentPageType = PageTypes.PRODUCT;
  } else if (pathName && pathName.indexOf('/c/') > -1) {
    currentPageType = PageTypes.LISTING;
  } else if (pathName && pathName.indexOf('/search/result/') > -1) {
    currentPageType = PageTypes.SEARCH_LISTING;
  }
  return currentPageType;
};
