import { pushEvent } from 'helpers/utils/dataLayer';
import { events } from 'constants/dataLayer';
import { logger } from '@nykaa/logger';

import { localStorageService } from 'client/service/storage';
import { reactStorageKeys } from 'constants/auth';
import { ChatParams, UserDataLayer } from './types';
import { defaultState } from './reducer';

const pushUser = (data: UserDataLayer) => {
  pushEvent(events.USER_DATA_FETCH, {
    user: data,
  });
};

interface User {
  email: string;
  id: string;
  firstName: string;
  isPro: boolean;
  groupId: string;
}

export const trackUser = (user: User) => {
  const { email, id, firstName, isPro, groupId } = user;

  pushUser({
    email,
    customerId: id,
    firstName,
    isLoggedIn: !!email,
    groupId,
    isPro,
  });
};

export const pushGuestVerloop = () => {
  pushEvent(events.VERLOOP_LOGOUT);
};

export const loadVerloop = (data?: ChatParams) => {
  if (!data || typeof data !== 'object') {
    pushEvent(events.VERLOOP_LOAD);
    return;
  }
  const { hash = '', unqTxnId = '', phoneNo = '' } = data || {};
  pushEvent(events.VERLOOP_LOAD, {
    hash,
    unqTxnId,
    phoneNo,
  });
};

export const getAuthFromStorage = () => {
  try {
    const reactConfigExpiryString = localStorageService.getItem(reactStorageKeys.CONFIG_EXPIRY);
    const reactConfigExpiry = parseInt(reactConfigExpiryString || '', 10);
    const expiryTime = new Date(reactConfigExpiry);
    const currentTime = new Date();

    if (expiryTime > currentTime) {
      const reactConfigData = localStorageService.getItem(reactStorageKeys.CONFIG);
      if (reactConfigData) {
        const parsedReactConfigData = JSON.parse(reactConfigData);
        if (parsedReactConfigData) {
          return parsedReactConfigData;
        }
      }
    } else {
      pushEvent('mp_reset');
      pushEvent(events.CONFIG_EXPIRED);
    }
  } catch (err) {
    logger.error(err, 'Error reading react config from storage');
  }

  return null;
};

export const storeAuthInStorage = (response: any) => {
  try {
    const currentTime = new Date().getTime();
    const timeoutPeriod = reactStorageKeys.EXPIRY_TIME;
    const expiryTime = new Date(currentTime + timeoutPeriod).getTime();
    const stringifiedResponse = JSON.stringify(response);
    localStorageService.setItem(reactStorageKeys.CONFIG, stringifiedResponse);
    localStorageService.setItem(reactStorageKeys.CONFIG_EXPIRY, `${expiryTime}`);
  } catch (err) {
    logger.info(err, 'Error Storing reactConfig');
  }
};

export const removeAuthFromStorage = () => {
  try {
    localStorageService.removeItem(reactStorageKeys.CONFIG);
  } catch (err) {
    logger.error(err, 'Error removeAuthFromStorage');
  }
};

export const updateCartCountInStorage = (cartCount: number = 0) => {
  const authDataInStorage = getAuthFromStorage();
  if (authDataInStorage) {
    try {
      const result = authDataInStorage?.data?.result;
      if (result) {
        authDataInStorage.data.result.cartItemsTotal = cartCount;
        storeAuthInStorage(authDataInStorage);
      }
    } catch (err) {
      logger.info(err, 'Error: updateCartCountInStorage');
    }
  }
};

export const addWishListedItemInStorage = (productId: string) => {
  const authDataInStorage = getAuthFromStorage();
  if (authDataInStorage) {
    try {
      const wishlistItems = authDataInStorage?.data?.result?.wishlistProducts || [];
      if (wishlistItems && Array.isArray(wishlistItems)) {
        const isItemInWishlist = wishlistItems.some(item => item === productId);
        if (!isItemInWishlist) {
          wishlistItems.push(productId);
          authDataInStorage.data.result.wishlistProducts = wishlistItems;
          storeAuthInStorage(authDataInStorage);
        }
      }
    } catch (err) {
      logger.info(err, 'Error: addWishListedItemInStorage');
    }
  }
};

export const removeWishListedItemInStorage = (productId: string) => {
  const authDataInStorage = getAuthFromStorage();
  if (authDataInStorage) {
    try {
      const wishlistItems = authDataInStorage?.data?.result?.wishlistProducts;
      if (wishlistItems && Array.isArray(wishlistItems)) {
        const filteredWishlistItems = wishlistItems.filter(item => item !== productId);
        authDataInStorage.data.result.wishlistProducts = filteredWishlistItems;
        storeAuthInStorage(authDataInStorage);
      }
    } catch (err) {
      logger.info(err, 'Error: removeWishListedItemInStorage');
    }
  }
};

export const getInitialAuthState = (requestCookies: any) => {
  let authState = defaultState;
  const { head_data_react: headDataReact } = requestCookies;
  const parsedHeadDataReact = JSON.parse(headDataReact || null);

  if (parsedHeadDataReact) {
    authState = {
      ...authState,
      user: {
        ...authState.user,
        isPro: parsedHeadDataReact.nykaa_pro === 1,
        groupId: parsedHeadDataReact.group_id,
      },
    };
  }

  return authState;
};

export const isRegisteredViaMobile = (emailId: string) => {
  const NYKAA_EMAIL_REGEX = new RegExp('^\\d{10}@mobile.nykaa.com$');
  return NYKAA_EMAIL_REGEX.test(emailId);
};
