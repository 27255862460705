import { DEFAULT_CONFIG_FLAGS } from 'shared/constants/configFlags';
import { ConfigFlags } from './types';

const ACTIVE_STRING = 'active';

export const transformConfigFlags = (rawData: any): ConfigFlags => {
  const configFlags = DEFAULT_CONFIG_FLAGS;

  if (rawData) {
    configFlags.isSmartLockActive =
      rawData.login_via_smart_lock === ACTIVE_STRING;
    configFlags.mobileMapping = {
      sendOtpCaptcha: rawData.mobile_map_send_otp_captcha === ACTIVE_STRING,
      verifyOtpCaptcha: rawData.mobile_map_verify_otp_captcha === ACTIVE_STRING,
    };
    configFlags.showBreadcrumb = Boolean(rawData?.bread_crumb?.status);
    configFlags.customerAlsoBought = rawData.customers_also_bought;
    configFlags.customerAlsoViewed = rawData.customers_also_viewed;
    configFlags.auth = {
      checkExistenceCaptcha: rawData.check_existence_captcha === ACTIVE_STRING,
      sendOtpCaptcha: rawData.send_otp_captcha === ACTIVE_STRING,
      loginCaptcha: rawData.login_captcha_react === ACTIVE_STRING,
      signupCaptcha: rawData.invisible_captcha_react === ACTIVE_STRING,
    };
  }

  return configFlags;
};
