
import { setCookie, getCookie } from 'shared/helpers/utils/cookie';
import { RUN, RUN_MAX_AGE } from 'constants/cookies';
import { randomNumberGenerator } from 'shared/helpers/utils/Numbers';
import { pushData } from 'helpers/utils/dataLayer';


export const setRandomNumber = (CB: (value: number)=> void) => {
  let run = Number(getCookie(RUN)) || randomNumberGenerator();
  setCookie(RUN, run, RUN_MAX_AGE);
  CB && CB(run);
};

export const setABFlag = (configs: any, run: number) => {
  if (!configs) {
    return;
  };

  Object.keys(configs).forEach((key: string) => {
    const { keyName, userPercentage, status, cookie } = configs[key];
    const data: any = {};
    if (status && keyName && (run <= userPercentage )) {
      data[keyName] = 'A';
    } else {
      data[keyName] = 'B';
    }
    pushData(data);
    if (cookie) {
      const value = (status && (run <= userPercentage)) ? '1' : '0';
      setCookie(cookie, value, RUN_MAX_AGE);
    }
  });
};
