import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose, Store } from 'redux';

import createRootReducer from './rootReducer';


interface StoreParams {
  initialState?: { [key: string]: object };
};


let store: Store;

export const configureUserStore = ({
  initialState,
}: StoreParams) => {
  const devTools =
    typeof window !== 'undefined'
    && typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionsBlacklist: [] });
    // TODO: add platform check


  const composeEnhancers = devTools || compose;

  store = createStore(
    createRootReducer(),
    initialState,
    composeEnhancers(
      applyMiddleware(
        ...[
          thunk,
        ]
      )
    )
  );

  return store;
};

export const getStore = () => store;


export const getInitialUserStore = () => {

};
