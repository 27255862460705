import { MobileVerificationState, MobileVerificationStepName } from 'User/types/mobileVerification';


const initialState: MobileVerificationState = {
  steps: [
    {
      stepName: MobileVerificationStepName.LOADING,
    },
  ],
  flowDisabled: false,
};

export default initialState;
