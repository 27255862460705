import { Dispatch } from 'redux';
import actions from './actionTypes';
import { getAuth, getHash, logOut, logOutAll } from './service';
import {
  addWishListedItemInStorage,
  loadVerloop,
  pushGuestVerloop,
  removeWishListedItemInStorage,
  updateCartCountInStorage,
} from './helper';
import { cleanUp } from 'helpers/auth';

export const fetchAuth = () => (dispatch: Dispatch) => {
  // FIXME: type for dispatch
  dispatch({
    type: actions.FETCH_AUTH_DATA_PROGRESS,
  });

  return getAuth()
    .then((authData) => {
      dispatch({
        type: actions.FETCH_AUTH_DATA_SUCCESS,
        payload: authData,
      });
    })
    .catch((err) => {
      dispatch({
        type: actions.FETCH_AUTH_DATA_FAILED,
      });
      return Promise.reject(err);
    });
};

export const setAuthCartCount = (itemCount: number = 0) => {
  updateCartCountInStorage(itemCount);
  return {
    type: actions.AUTH_CART_COUNT,
    payload: itemCount || 0,
  };
};

export const addToAuthWishlist = (productId: string = '') => {
  addWishListedItemInStorage(productId);
  return {
    type: actions.AUTH_WISHLIST_ADD,
    payload: productId || '',
  };
};

export const removeFromAuthWishlist = (productId: string = '') => {
  removeWishListedItemInStorage(productId);
  return {
    type: actions.AUTH_WISHLIST_REMOVE,
    payload: productId || '',
  };
};

export const doLogOut = () => (dispatch: Dispatch) =>
  logOut()
    .then(() => {
      dispatch({
        type: actions.LOGOUT,
      });
      pushGuestVerloop();
      // This is to notify browser that the user has logged out & shouldn't be logged in automatically.
      cleanUp();
    })
    .catch((err) => {
      // * No Action dispatch needed here, so just throwing up the error as the component can handle the failure.
      throw err;
    });

export const logoutFromAllSession = () => (dispatch: Dispatch) =>
  logOutAll()
    .then(() => {
      dispatch({
        type: actions.LOGOUT_ALL,
      });
      pushGuestVerloop();
      // This is to notify browser that the user has logged out & shouldn't be logged in automatically.
      cleanUp();
    })
    .catch((err) => {
      // * No Action dispatch needed here, so just throwing up the error as the component can handle the failure.
      throw err;
    });

export const getUserHash = () => async () => {
  const data = await getHash();
  loadVerloop(data);
};
