import { Action } from 'shared/store/types';
import cmsEvents from './actionTypes';
import { getInlineScriptSource } from './helper';

const cmsReducer = (state = { isFetchingError: false }, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case cmsEvents.FETCH_CMS_DATA_SUCCESS: {
      const jsContentUpdatedData = getInlineScriptSource(payload.jsContent);
      return {
        ...state,
        cmsData: payload.pageData,
        jsContent: jsContentUpdatedData,
      };
    }
    case cmsEvents.FETCH_CMS_DATA_FAILED:
      return {
        ...state,
        isFetchingError: true,
      };
    default:
      return state;
  }
};

export default cmsReducer;
