import React from 'react';
import { Global, css } from '@emotion/core';

import { BODY_OVERFLOW_FOR_MODAL } from 'shared/constants/dom';
import normalize from './normalize';
import SourceSansProLightWoff from './assets/fonts/ssp-l.woff2';
import SourceSansProMediumWoff from './assets/fonts/ssp-m.woff2';
import SourceSansProBoldWoff from './assets/fonts/ssp-b.woff2';
// import InterRegularWoff from './assets/fonts/Inter-Regular.woff2';
// import InterMediumWoff from './assets/fonts/Inter-Medium.woff2';
// import InterSemiBoldWoff from './assets/fonts/Inter-SemiBold.woff2';
import InterWoff from './assets/fonts/Inter.woff2';


const BaseStyles = () => (
  <Global
    styles={css`
      ${normalize}
      *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        -webkit-tap-highlight-color: transparent;
      }
      @font-face {
        font-family: "Source Sans Pro";
        src: url("${SourceSansProLightWoff}") format("woff2");
          font-weight: 400;
          font-style: normal;
          font-display: swap;
      }
      @font-face {
        font-family: "Source Sans Pro";
        src: url("${SourceSansProMediumWoff}") format("woff2");
          font-weight: 500;
          font-style: normal;
          font-display: swap;
      }
      @font-face {
        font-family: "Source Sans Pro";
        src: url("${SourceSansProBoldWoff}") format("woff2");
          font-weight: 600;
          font-style: normal;
          font-display: swap;
      }
      @font-face {
        font-family: "Inter";
        src: url("${InterWoff}") format("woff2");
          font-weight: 400;
          font-style: normal;
          font-display: swap;
      }
      @font-face {
        font-family: "Inter";
        src: url("${InterWoff}") format("woff2");
          font-weight: 500;
          font-style: normal;
          font-display: swap;
      }
      @font-face {
        font-family: "Inter";
        src: url("${InterWoff}") format("woff2");
          font-weight: 600;
          font-style: normal;
          font-display: swap;
      }
      body {
        font-family: "Inter",Roboto,Arial,sans-serif;
        background-color: #f3f3f3;
        font-size: 14px;

        &.${BODY_OVERFLOW_FOR_MODAL} {
          overflow: hidden;
        }
        line-height: 1;
      }

      :focus {
        outline: 0;
      }
      ol, ul {
        list-style: none;
      }
    `}
  />
);

export default BaseStyles;
