import {
  MobileMappingResponse, GetMappingStatusError, MobileMappingStatus,
} from 'User/types/mobileVerification';
import { statusMessages } from 'shared/constants/statusCodes';
import { NETWORK_ERROR } from 'shared/constants/toastMessages';

import { GET_MOBILE_MAPPING_STATUS_ERROR } from '../constants/errorMessages';


export const transformMobileMappingStatus = (error: (null|Error), apiResponse: any): MobileMappingResponse => {
  if (error) { // * A network error
    return {
      error: {
        abortFlow: true,
        errorToLog: `Network Error: ${GET_MOBILE_MAPPING_STATUS_ERROR}`,
        messageToDisplay: NETWORK_ERROR,
        type: GetMappingStatusError.NETWORK_ERROR,
      },
    };
  }

  const { status, response } = apiResponse || {};
  const {
    status: mappingStatus,
    mobile_number: mobileNumber,
    editingAllowed,
  } = response;

  if (status === statusMessages.SUCCESS) {
    switch (mappingStatus) {
      case MobileMappingStatus.GUEST_USER:
        return {
          error: {
            takeToHome: true,
            abortFlow: false,
            type: GetMappingStatusError.GUEST_USER,
            errorToLog: `GuestUser: ${GET_MOBILE_MAPPING_STATUS_ERROR}`,
          },
        };

      case MobileMappingStatus.VERIFIED:
        return {
          status: MobileMappingStatus.VERIFIED,
        };

      case MobileMappingStatus.MULTIPLE_OR_NO_MOBILE:
        if (editingAllowed) {
          return {
            status: mappingStatus,
            mobileNumber: mobileNumber,
            isEditable: true,
            isEligible: true,
          };
        }

        return {
          error: {
            takeToHome: true,
            abortFlow: false,
            type: GetMappingStatusError.NOT_ELIGIBLE,
            errorToLog: `User Not Eligible: ${GET_MOBILE_MAPPING_STATUS_ERROR}`,
          },
        };
      case MobileMappingStatus.SUGGEST_MOBILE_NUMBER:
      case MobileMappingStatus.UN_VERIFIED_MOBILE:
        // 2: user has multiple mobiles or no mobiles -> take user to landing - IDEAL FLOW
        // 3: a mobile number is suggested -> take the user to Landing - IDEAL FLOW
        // 4: mobile number exists but linked to other -> take user to landing - IDEAL FLOW
        // 5: mobile number is unverified -> take the user to Landing - - IDEAL FLOW
        return {
          status: mappingStatus,
          mobileNumber: mobileNumber,
          isEditable: !!(editingAllowed),
          isEligible: true,
        };
        case MobileMappingStatus.ALREADY_LINKED:
          return {
            status: mappingStatus,
            mobileNumber: mobileNumber,
            isEditable: !!(editingAllowed),
            isEligible: !!(editingAllowed),
          };

      default:
        return {
          error: {
            abortFlow: true,
            errorToLog: `Unknown error: status returned - ${mappingStatus} ${GET_MOBILE_MAPPING_STATUS_ERROR}`,
            type: GetMappingStatusError.OTHER_ERROR,
          },
        };
    }
  }

  /**
   * This covers the following cases
   * 1. Invalid token
   * 2. Incomplete request parameters
   * 3. Guest user
   */
  if (mappingStatus === '0') {
    // GUEST USER - take user to Homepage
    return {
      error: {
        takeToHome: true,
        abortFlow: false,
        type: GetMappingStatusError.GUEST_USER,
        errorToLog: `GuestUser: ${GET_MOBILE_MAPPING_STATUS_ERROR}`,
      },
    };
  }

  const errorToLog = `${GET_MOBILE_MAPPING_STATUS_ERROR}`;

  return {
    error: {
      abortFlow: true,
      type: GetMappingStatusError.OTHER_ERROR,
      errorToLog,
    },
  };
};

