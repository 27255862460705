import { logger } from '@nykaa/logger';


export const localStorageService = {
  getItem: (key: string) => {
    if (!localStorage || !key) {
      return null;
    }
    try {
      return localStorage.getItem(key);
    } catch (error) {
      logger.error(error, `Error Fetching ${key} from Local Storage`);
    }
  },
  setItem: (key: string, value: string) => {
    if (!localStorage || !key || value === null) {
      return false;
    }
    try {
      localStorage.setItem(key, value);
      return true;
    } catch (error) {
      logger.error(error, `Error Setting ${key} in Local Storage`);
    }
  },
  removeItem: (key: string) => {
    if (!localStorage || !key) {
      return false;
    }

    try {
      localStorage.removeItem(key);
      return true;
    } catch (error) {
      logger.error(error, `Error removing ${key} from Local Storage`);
    }
  },
};
