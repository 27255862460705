import {
    ANDROID,
    ANDROID_REG_EXP,
    IOS,
    IOS_REG_EXP,
    MOBILE_REG_EXP,
    MWEB,
} from 'shared/constants/devices';


export const getDeviceType = () => {
    let appType: 'android' | 'ios' | 'mweb' | 'dweb' = 'dweb';

    const userAgent = window.navigator.userAgent || '';

    if (ANDROID_REG_EXP.test(userAgent)) {
        appType = ANDROID;
    }

    if (IOS_REG_EXP.test(userAgent)) {
        appType = IOS;
    }

    if (MOBILE_REG_EXP.test(userAgent)) {
        appType = MWEB;
    }

    return appType;
};

export const getEnvironment = () => {
    const env = process.env.NODE_ENV || '';

    if (env.indexOf('prod') > -1) {
        return 'prod';
    } else if (env.indexOf('smoke') > -1) {
        return 'smoke';
    } else if (env.indexOf('perf') > -1) {
        return 'perf';
    }

    return 'preprod';
};
