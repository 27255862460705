export const reactStorageKeys = {
  CONFIG: 'REACT_CONFIG',
  CONFIG_EXPIRY: 'REACT_CONFIG_EXPIRY',
  EXPIRY_TIME: 3600000, // THIS IS MILLISECONDS
};

export const AB_AUTH_DWEB = 'AB_AUTH_DWEB';
export const MOBILE_VERIFICATION_URL = '/user/mobile_verification';
export const LOGIN_SIGNUP_START = 'login_signup_start';
export const LOGIN_PROCEED_CLICKED = 'login_proceed_clicked';
export const LOGINTYPE = {
  PHONE_EMAIL: 'phone_email',
  EMAIL: 'email',
  PHONE: 'phone',
  TRUECALLER: 'trueCaller',
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
};

export const LOGIN_LOCATION = {
  MYACCOUNT_LOGIN_BTN: 'myAccount_login_btn',
  WISHLISTICON_PRODUCT: 'wishlistIcon_product',
  REVIEW_WRITE: 'review:write',
};

export const LOGOUT_START = 'logout_start';
