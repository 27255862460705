export enum Device {
  ANDROID,
  IOS,
  WEB,
  DESKTOP,
}

export interface Client {
  device: Device;
  version?: string;
}

export interface Region {
  regionHeader?: string;
  regionHeaderCountry?: string;
}
