import React from 'react';
import styled from '@emotion/styled';
import AnimatedLoader from 'shared/components/BelowTheFoldControl/AnimatedLoader';

const CardWrapper = styled.div`
  flex: 1;
  border-bottom: 2px solid #e1e1e1;
  border-right: 2px solid #e1e1e1;
  max-width: 100%;
  padding: 30px 15px 30px 15px;
`;

const Image = styled(AnimatedLoader)`
  width: 100%;
  height: 400px;
  display: inline-block;
  margin-bottom: 10px;
`;

const DetailsWrapper = styled.div``;

const Line = styled(AnimatedLoader)`
  height: 7px;
  margin-bottom: 10px;
  width: 100%;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  background-color: #fff;
  min-height: 300px;
`;

export const ProductLoader = () => (
  <Wrapper>
    <CardWrapper>
      <Image className="client" />
      <DetailsWrapper>
        <Line className="client" />
        <Line className="client" />
        <Line className="client" />
        <Line className="client" />
        <Line className="client" />
      </DetailsWrapper>
    </CardWrapper>
  </Wrapper>
);
