import React from 'react';
import { Route } from 'react-router-dom';

import routes from 'routeConfig';
import Scroll from './View';


const routesWithScroll: string[] = [];
routes.forEach((route) => {
  if (route.scroll) {
    routesWithScroll.push(route.path);
  }
});

function SmartScroll() {
  return (
    <Route
      path={routesWithScroll}
      exact
    >
      <Scroll />
    </Route>
  );
}

export default SmartScroll;
