import { Action } from 'shared/store/types';
import actionTypes from './actionTypes';
import { ProductState } from './types';

const defaultState: ProductState = {
  loading: false,
  isFetchingError: false,
  banners: undefined,
  product: null, // FIXME: Store only the data that is relevant
  activeTab: 0,
  remaining_inventories_ids: [],
  queryParamsObject: {},
};

const productReducer = (state = defaultState, action: Action) => {
  const { type, payload } = action;
  const { product } = state;

  switch (type) {
    case actionTypes.FETCH_PRODUCT_DATA_PROGRESS:
      return {
        ...state,
        loading: true,
        isNotFound: false,
        isFetchingError: false,
      };

    case actionTypes.FETCH_PRODUCT_DATA_FAILED:
      return {
        ...state,
        loading: false,
        isNotFound: false,
        isFetchingError: true,
      };

    case actionTypes.FETCH_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        isNotFound: false,
        isFetchingError: false,
        product: payload,
      };

    case actionTypes.CHANGE_PRODUCT_VARIANT:
      return {
        ...state,
        product: {
          ...product,
          selectedVariantId: payload,
        },
      };
    case actionTypes.FETCH_OFFERS_SUCCESS:
      return {
        ...state,
        product: {
          ...product,
          offersData: payload,
        },
      };

    case actionTypes.PRODUCT_LIKE_REVIEW: {
      const { reviewId, count } = payload;
      if (product && product.latestReviews) {
        const { latestReviews } = product;
        // ? latestReviews is ideally a very small array... tops of length 5. Shouldn't be a concern for performance
        const indexOfReviewId = latestReviews?.findIndex(review => review.id === reviewId);
        const selectedReview = latestReviews[indexOfReviewId];
        if (selectedReview && (count || count === 0)) {
          selectedReview.isLikedByUser = !selectedReview.isLikedByUser;
          selectedReview.likeCount = count;

          return {
            ...state,
            product: {
              ...product,
              latestReviews: [...latestReviews],
            },
          };
        }
      }
      return state;
    }

    case actionTypes.BANNERS_SUCCESS: {
      const { widgets, remaining_inventories_ids, queryParamsObject } = payload;
      return {
        ...state,
        banners: widgets,
        remaining_inventories_ids,
        queryParamsObject,
      };
    }

    case actionTypes.PRODUCT_UNMOUNT:
      return {
        ...state,
        isFetchingError: false,
      };

    case actionTypes.PRODUCT_REVIEW_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };

    default:
      return state;
  }
};

export default productReducer;
