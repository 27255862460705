interface TransformedAuthData {
  cartItemsCount: number;
  email: string;
  firstName: string;
  formKey: string;
  groupId: string;
  id: string;
  isLoyal: boolean;
  isPro: boolean;
  lastName: string;
  rewardPoints: boolean;
  userName: string;
  wishlist: string[],
}


export const convertWishlistToArray = (wishlist: any): string[] => {
  const wishlistIndices = Object.keys(wishlist);
  const wishlistItems: string[] = [];

  wishlistIndices.forEach((wishlistIndex) => {
    wishlistItems.push(wishlist[wishlistIndex]);
  });

  return wishlistItems;
};


export const transformAuthData = (authData: any) => {
  const {
    cartItemsTotal,
    form_key: formKey,
    customerData: {
      id,
      loginEmail,
      loginUsername,
      gfirstname: gFirstName,
      glastname: gLastName,
      group_id: groupId,
      nykaa_pro: isPro,
      is_loyal: isLoyal,
      rewardPoints = 0,
    },
    wishlistProducts,
  } = authData;

  const transformedAuthData: TransformedAuthData = {
    cartItemsCount: cartItemsTotal || 0,
    email: loginEmail || '',
    firstName: gFirstName || '',
    formKey,
    groupId: groupId || '',
    id: id || '',
    isLoyal,
    isPro: isPro || false,
    lastName: gLastName || '',
    rewardPoints,
    userName: loginUsername || '',
    wishlist: convertWishlistToArray(wishlistProducts || {}) || [],
  };

  return transformedAuthData;
};
