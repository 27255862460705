import { appendQueryStringToUrl } from 'shared/helpers/utils/url';
import { Device } from 'shared/types';
import { logger } from '@nykaa/logger';
import { localStorageService } from 'client/service/storage';
import { pages } from 'shared/constants/urls';
import { logErrors } from 'shared/helpers/utils/logErrors';
import { getAuthFromStorage } from 'shared/store/auth/helper';
import { deleteCookie } from '@nykaa/utils/cookies';
import {
  FACEBOOK_LOGIN_COOKIE,
  GOOGLE_LOGIN_COOKIE,
} from 'store/smartLock/constants';
import { USER_DATA_STORAGE_KEY } from 'constants/cookies';

const MOB_MAP = 'M_MAP';
const MOB_NO = 'M_NO';
const AUTO_FILL = 'Atf';
const AUTO_FILL_SHOWN = 'af_s';

/**
 * @deprecated
 */
export const redirectAuthPage = () => {
  try {
    const CURRENT_URL = 'CURRENT_URL';
    const locationPathName = `${window.location.pathname}${window.location.search}`;
    localStorageService.setItem(CURRENT_URL, locationPathName);
  } catch (err) {
    logger.info(err, 'Error: authCurrentUrlInStorage');
  }
  window.location.href = pages.AUTH_PAGE;
};

export const handleAuthNavigation = (
  redirectUrl: string,
  device: Device = Device.WEB
) => {
  const redirectQueryString = `redirect=${encodeURIComponent(redirectUrl)}`;
  switch (device) {
    case Device.ANDROID:
      window.AndroidFunction.initiateLogin(redirectUrl);
      break;
    case Device.IOS:
      window.location.href = `nykaaioslanding://initiateLogin?nextUrlToLoadIfLoginSuccess=${encodeURIComponent(
        redirectUrl
      )}`;
      break;
    case Device.WEB:
    default:
      window.location.href = appendQueryStringToUrl(
        pages.AUTH_PAGE,
        redirectQueryString
      );
      break;
  }
};

export const getAuthSteps = () => {
  try {
    const step = sessionStorage.getItem(MOB_MAP);
    if (step) {
      const parsed = JSON.parse(step);
      return parsed;
    }
  } catch (err) {
    logErrors(err, 'getAuthSteps');
  }
};

export const deleteAuthSteps = () => {
  try {
    sessionStorage.removeItem(MOB_MAP);
  } catch (err) {
    logErrors(err, 'deleteAuthSteps');
  }
};

export const setMobileNumberLocal = (mobNumber) => {
  try {
    return localStorageService.setItem(MOB_NO, mobNumber);
  } catch (err) {
    logErrors(err, 'setMobileNumberLocal');
  }
};

export const setAutofillFlag = (val: string) => {
  try {
    sessionStorage.setItem(AUTO_FILL, val);
  } catch (err) {
    logErrors(err, 'setAutofillFlag');
  }
};

export const getMobileNumberLocal = () => {
  try {
    return localStorageService.getItem(MOB_NO);
  } catch (err) {
    logErrors(err, 'getMobileNumberLocal');
  }
};

export const isAutoFillShown = () => {
  try {
    return sessionStorage.getItem(AUTO_FILL_SHOWN);
  } catch (err) {
    logErrors(err, 'isAutoFillShown');
  }
};

export const setAutoFillShown = () => {
  try {
    return sessionStorage.setItem(AUTO_FILL_SHOWN, '1');
  } catch (err) {
    logErrors(err, 'setAutoFillShown');
  }
};

export const isLoyalUser = () => {
  const auth = getAuthFromStorage() || {};
  return auth.data?.result?.customerData?.is_loyal;
};

export const reloadPage = () => {
  // We are handling reload from GTM when user logouts in VERLOOP_LOGOUT rule (when verloop logout api success)
  // this reload will never execute.
  // To remove 3 party dependency or as a fallback we mention location.reload here
  setTimeout(() => {
    location.reload();
  }, 1000);
};

export const cleanUp = () => {
  navigator.credentials.preventSilentAccess();
  deleteCookie(FACEBOOK_LOGIN_COOKIE);
  deleteCookie(GOOGLE_LOGIN_COOKIE);
  deleteCookie(USER_DATA_STORAGE_KEY);
  localStorageService.removeItem('loginLocation');
};

export const setLoginLocation = (location: string) => {
  localStorageService.setItem('loginLocation', location);
};
