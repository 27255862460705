import AllReview from 'pages/AllReview';
import CategoryListing from 'pages/CategoryListing';
import SearchListing from 'pages/SearchListing';
import AllReviewImages from 'pages/AllReviewImages';
import {
  Home,
  NotFound,
  Cms,
  Product,
  ProRegistration,
  Wishlist,
  MyProfile,
  Cmswrapper,
  MyCoupons,
  Auth,
} from '../pages';

const routes = [
  {
    path: '/sp/:id/:pageData?',
    component: Cms,
    header: true,
    footer: true,
    scroll: true,
  },
  {
    path: ['/', '/home'],
    component: Home,
    header: true,
    footer: true,
    exact: true,
    scroll: true,
  },
  {
    path: '/:slug/p/:id',
    component: Product,
    header: true,
    footer: true,
    exact: true,
    scroll: true,
  },
  {
    path: '/:slug/lp',
    component: Cmswrapper,
    header: true,
    footer: true,
    scroll: true,
  },
  {
    path: '/:url*/c/:id',
    component: CategoryListing,
    header: true,
    footer: true,
    scroll: false,
  },
  {
    path: ['/search/result', '/src/:slug', '/dp/:slug'],
    component: SearchListing,
    header: true,
    footer: true,
    exact: true,
    scroll: false,
  },
  {
    path: '/myProfile',
    component: MyProfile,
    header: true,
    footer: true,
    scroll: true,
  },
  {
    path: '/wishlist/',
    component: Wishlist,
    header: true,
    footer: true,
    scroll: true,
  },
  {
    path: '/myCoupons/',
    component: MyCoupons,
    header: true,
    footer: true,
    scroll: true,
  },
  {
    path: '/auth',
    component: Auth,
    header: false,
    footer: false,
    scroll: true,
  },
  {
    path: '/nykaa-pro-sign-up',
    component: ProRegistration,
    header: true,
    footer: true,
    exact: true,
    scroll: true,
  },
  {
    path: ['/:slug/reviews/:id'],
    component: AllReview,
    header: true,
    footer: true,
    scroll: true,
  },
  {
    path: '/:slug/review-images/:id',
    component: AllReviewImages,
    header: true,
    footer: true,
    scroll: true,
  },
  {
    path: '*',
    component: NotFound,
    status: 404,
  },
];

export default routes;
