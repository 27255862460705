import { pushData } from './dataLayer/index';
import { SITE_VISIT_COUNT } from 'constants/cookies';
import { logger } from '@nykaa/logger';
import { getCookie, setCookie } from './cookie';


function setUpSessionCount() {
  try {
    const sessionCountFromCookie = getCookie(SITE_VISIT_COUNT) as string;
    const sessionCount = parseInt(sessionCountFromCookie, 10);
    const newSessionCount = sessionCount ? (sessionCount + 1) : 1;

    pushData({ visitCount: newSessionCount });
    setCookie(SITE_VISIT_COUNT, newSessionCount, 365);
  } catch (err) {
    logger.error(err, 'Error setting session count');
  }
}

export default setUpSessionCount;
