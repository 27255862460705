import { logger } from '@nykaa/logger';
import { ApiHelper } from '@nykaa/utils/network';
import { ROUTES, getUrl } from 'shared/api/urls';
import { contentTypes } from 'shared/constants/requestHeaders';


interface GoogleParams {
  firstName: string;
  lastName: string;
  email: string;
  googleConnectId: string;
  idToken: string;
}

interface FbParams {
  name:string;
  id: string;
  email: string;
  accessToken: string;
}

export const getGraphObj = async (accessToken: string) => {
  const url = getUrl(ROUTES.GRAPH_API);
  try {
    const { data } = await ApiHelper(`${url}`, 'get', {}, { authorization: `OAuth ${accessToken}` });

    if (!data) {
      return Promise.reject('FB data not found');
    }
    return data;
  } catch (e) {
    logger.error(e, 'Error Fetching Graph API');
    throw e;
  }
};

export const fbAPI = async (fbData: FbParams) => {
  const {
    name,
    id,
    email,
    accessToken,
  } = fbData;
  const nameArray = name.split(' ');
  const formData = new FormData();
  formData.append('facebook_uid', id);
  formData.append('firstname', nameArray[0]);
  formData.append('lastname', nameArray[nameArray.length - 1]);
  formData.append('email', email);
  formData.append('web_version', '1');
  formData.append('input_token', accessToken);
  const url = getUrl(ROUTES.FB_API);

  try {
    const { data } = await ApiHelper(`${url}`, 'post', formData, { 'Content-Type':  contentTypes.URL_ENCODED });

    if (!data) {
      return Promise.reject('FB data not found');
    }

    return data;
  } catch (e) {
    logger.error(e, 'Error Fetching FB API');
    throw e;
  }
};

export const googleAPI = async (googleData: GoogleParams) => {
  const {
    idToken,
    firstName,
    lastName,
    email,
    googleConnectId,
  } = googleData;

  const formData = new FormData();
  formData.append('id_token', idToken);
  formData.append('firstname', firstName);
  formData.append('lastname', lastName);
  formData.append('web_version', '1');
  formData.append('email', email);
  formData.append('inchoo_googleconnect_id', googleConnectId);

  const url = getUrl(ROUTES.GOOGLE_API);
  try {
    const { data } = await ApiHelper(`${url}`, 'post', formData, { 'Content-Type':  contentTypes.URL_ENCODED });

    if (!data) {
      return Promise.reject('Google data not found');
    }

    return data;
  } catch (e) {
    logger.error(e, 'Error Fetching Google API');
    throw e;
  }
};

