import { combineReducers } from 'redux';
import auth from 'store/auth/reducer';
import app from 'store/app/reducer';
import remoteConfig from 'store/remoteConfig/reducer';

import mobileVerification from './mobileVerification/reducer';
import userPortfolio from './userPortfolio/reducer';


const createRootReducer = () => combineReducers({
  appReducer: app,
  auth,
  mobileVerification,
  remoteConfig,
  userPortfolio,
});


export default createRootReducer;
