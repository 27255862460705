export const SITE_VISIT_COUNT = 'SITE_VISIT_COUNT';

export const USER_DATA_STORAGE_KEY = 'head_data_react';

export const PRO_COOKIE_KEY = 'pro';

export const RUN = 'run';

export const RUN_MAX_AGE = 30;

export const RULE_ID = 'rID';
