import { AnswerOptions } from 'shared/User/types/userPortfolio';
import { QuestionData } from 'shared/User/types/userPortfolio/questionData';


export const getSelectedValues = (answers: AnswerOptions[]) => {
  // values will be selected options
  const selectedValues: string[] = [];
  answers && answers.map((answerData) => {
    selectedValues.push(answerData.optionId);
  });
  return selectedValues;
};

export const isNotAnswerIncluded = (answers: UserDefinedObject, questionAttribute: string) => {
  if (answers?.[questionAttribute]?.attributes?.[0]?.optionId !== '-1') {
    return true;
  }
  return false;
};

interface ActiveIndexProps {
  showPrivateAttribute: string;
  questions?: QuestionData[];
  answers?: UserDefinedObject;
}
// if showPrivateAttribute is true then when user
// lands for the fist time direct checkbox question need to be shown
// so active index will be of that question otherwise the questin whose answer is not given will be the active index.
export const getActiveIndex = (props: ActiveIndexProps) => {
  const {
    showPrivateAttribute,
    questions,
    answers,
  } = props;

  const checkAnswerExists = answers && (
    Object.keys(answers).length > 0 ) &&
    questions && (
    questions.length > 0
  );
  let activeIndex = 0;
  if (checkAnswerExists) {
    questions?.some((question, index) => {
      if (showPrivateAttribute) {
        if ((index > 3) && (!(answers?.[question?.attribute]?.toString()))) {
          activeIndex = index;
          return true;
        } else if (index + 1 === questions.length) {
          activeIndex = index + 1;
          return true;
        }
      }
      else if (!(answers?.[question?.attribute]?.toString())) {
        activeIndex = index;
        return true;
      } else if (index + 1 === questions.length) {
        activeIndex = index + 1;
        return true;
      }
    });
  }
  return activeIndex;
};
