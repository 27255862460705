import React from 'react';
import { useSelector } from 'react-redux';
import { ChatWidget } from '@nykaa/chat-widget';
import { useIsLoggedIn } from 'hooks/auth';
import { State } from 'store/types';
import { platformNames } from 'shared/constants';

interface Props {
  setShowZendeskChat: React.Dispatch<React.SetStateAction<boolean>>;
}

const Zendesk = ({ setShowZendeskChat }: Props) => {
  const isLoggedIn = useIsLoggedIn();
  const {
    user: { formKey },
  } = useSelector((state: State) => state.auth);
  const { pageType = '' } = useSelector((state: State) => state.appReducer);

  let DOMAIN = __PLATFORM__;
  if (__PLATFORM__ === platformNames.MEN) {
    DOMAIN = 'nykaa_men';
  }

  return (
    <ChatWidget
      formKey={formKey}
      domain={DOMAIN.toUpperCase()}
      zendeskExternalId={process.env.ZENDESK_EXTERNAL_ID}
      interactionLocation={pageType || ''}
      isLoggedIn={isLoggedIn}
      transport={'zendesk'}
      isErrorScreenDisabled={true}
      onChatWidgetClosed={() => setShowZendeskChat(false)}
    />
  );
};

export default Zendesk;
