
import { connect } from 'react-redux';

import { State } from 'store/types';

import { setRUN as _setRUN } from 'store/app/actions';
import View from './View';


const mapDispatchToProps = ({
  setRUN: _setRUN,
});
const mapStateToProps = (state: State) => {
  const {
    remoteConfig,
   } = state;

  return {
    configs: remoteConfig.configs?.AB,
  };
};


export default connect( mapStateToProps, mapDispatchToProps)(View);
