import { ConfigFlags } from 'store/app/types';

export const DEFAULT_CONFIG_FLAGS: ConfigFlags = {
  isSmartLockActive: false,
  mobileMapping: {
    sendOtpCaptcha: false,
    verifyOtpCaptcha: false,
  },
  auth: {
    checkExistenceCaptcha: false,
    sendOtpCaptcha: false,
    loginCaptcha: false,
    signupCaptcha: false,
  },
};
