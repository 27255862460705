import { logger } from '@nykaa/logger';


export const logErrors = (error: Error, customMessage: string) => {
  const statusCode = error.response?.status;
  const errorMessage = error.message;
  if (statusCode) {
    logger.error(error, `${statusCode}: ${customMessage}`);
  } else {
    logger.error(error, errorMessage);
  }
};
