import { platformNames } from 'shared/constants';

export const SEO_METADATA = {
  [platformNames.NYKAA]: {
    title: 'Buy Cosmetics Products & Beauty Products Online in India at Best Price | Nykaa',
    keywords:
      'Nykaa, Nyka, Nykaa.com, Nika, Nyyka, Nyka.com, Nykaa online, Nykaa India, buy cosmetics online, cosmetics online, buy beauty products online, beauty products online, buy cosmetics india, buy beauty products india',
    description:
      'Buy cosmetics & beauty products online from Nykaa, the online shopping beauty store. Browse makeup, health products & more from top beauty brands. ✔ free shipping* ✔ Cash on Delivery',
  },
  [platformNames.MEN]: {
    title: 'Mens Grooming, Hair Care, Skin Care & Wellness Products Online at Best Prices in India | NykaaMan',
    description:
      'Buy Mens Grooming, Hair & Skin Care & Wellness Products Online in India from NykaaMan. Explore a wide range of styling products for men at low prices from top brands. \u2713Original Products \u2713COD \u2713Free Shipping',
    keywords: '',
  },
};

export const SEO_DATA = 'SEO_DATA';
export const DWEB = 'dweb';
