import styled, { CreateStyled } from '@emotion/styled';
import { Theme as CustomTheme } from '@nykaa/ui-components/themes/types';


interface Theme extends CustomTheme {
  darkGrey: string;
  lightGrey: string;
  darkGreen: string;
  lightOrange: string;
  brickColor: string;
  lightBorder: string;
  lightBlack: string;
  brandPrimary: string;
  brandSecondary: string;
  brandPrimaryRgba:(opacity: number) => string;
  featuresBak: string;
  featuresColor: string;
  newsletterBak: string;
  brandLight: string;
  footerLinks: string;
  footerBak: string;
  footerHoverLink: string,
}

export default styled as CreateStyled<Theme>;
