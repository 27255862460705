import createLightTheme from '@nykaa/ui-components/themes/lightTheme/createLightTheme';
import { getDesktopTypography } from '@nykaa/ui-components/themes/lightTheme/typography';
import typoGraphyTokens from '@nykaa/ui-components/themes/shared/typography';
import { platformNames } from 'shared/constants';

const textColors = {
  darkGrey: '#3A4047',
  lightGrey: '#5C6874',
  darkGreen: '#4FC668',
  lightOrange: '#FF8047',
  brickColor: '#FF7271',
  lightBorder: '#D6D9DC',
  lightBlack: '#595E63',
};
const nykaaPrimaryRgba = (opacity: number): string =>
  `rgba(232, 0, 113,${opacity})`;
const menPrimaryRgba = (opacity: number): string =>
  `rgba(0, 50, 67,${opacity})`;

const NYKAA = {
  brandPrimary: '#E80071',
  brandSecondary: '#FFE1F1',
  brandLight: '#FAC1DE',
  ...textColors,
  brandPrimaryRgba: nykaaPrimaryRgba,
  featuresBak: '#F3F4F5',
  featuresColor: '#3A4047',
  newsletterBak: '#3A4047',
  footerLinks: '#8c8d94',
  footerHoverLink: '#fc2779',
  footerBak: '#f3f3f3',
};

const MEN = {
  brandPrimary: '#003243',
  brandSecondary: '#A8D6F0',
  brandLight: '#62A3C4',
  ...textColors,
  brandPrimaryRgba: menPrimaryRgba,
  featuresBak: '#F3F4F5',
  featuresColor: '#3A4047',
  newsletterBak: '#3A4047',
  footerHoverLink: '#1e6b7b',
  footerLinks: '#003243',
  footerBak: '#fff',
  priceRevealBg: '#F3F4F5',
  warningColor: '#DE8000',
  lightThemeInverse: '#62A3C4',
};

export const getAppTheme = (): Theme => {
  switch (__PLATFORM__) {
    case platformNames.MEN:
      return {
        ...MEN,
        ...createLightTheme({
          colors: {
            primary: MEN.brandPrimary,
            primary40: MEN.brandPrimary,
            primary30: MEN.brandLight,
            primary10: MEN.priceRevealBg,
            warning: MEN.warningColor,
            primaryInverse: MEN.lightThemeInverse,
            secondaryInverse: MEN.lightThemeInverse,
            secondary: MEN.brandPrimary,
          },
          typography: getDesktopTypography(typoGraphyTokens),
        }),
      };

    default:
      return {
        ...NYKAA,
        ...createLightTheme({
          colors: { primary: NYKAA.brandPrimary },
          typography: getDesktopTypography(typoGraphyTokens),
        }),
      };
  }
};
