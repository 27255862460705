import {
  VerifyMappingOtpError,
  VerifyMappingOtpResponse,
  VerifyMappingOtpStatus,
} from 'User/types/mobileVerification';
import { standardErrorMessages } from 'shared/constants';
import { statusMessages } from 'shared/constants/statusCodes';
import { NETWORK_ERROR } from 'shared/constants/toastMessages';
import {
  VERIFY_MAPPING_OTP_API_ERROR,
  prefixForMobileMappingErrorLogs,
} from 'User/store/mobileVerification/constants/errorMessages';


export const transformVerifyMappingOtp = (error: (null | Error), apiResponse: any): VerifyMappingOtpResponse => {
  if (error) {
    /**
     * HANDLE NETWORK ERROR
     * In this case we prompt the user that some Network error has happened
     */
    return {
      error: {
        abortFlow: false,
        type: VerifyMappingOtpError.NETWORK_ERROR,
        errorToLog: `${error.response?.status} ${prefixForMobileMappingErrorLogs.NETWORK_ERROR}: ${VERIFY_MAPPING_OTP_API_ERROR}`,
        messageToDisplay: NETWORK_ERROR, // GENERIC NETWORK ERROR MESSAGE
      },
    };
  }

  const { status, response } = apiResponse;

  if (status === statusMessages.SUCCESS) {
    return {
      status: VerifyMappingOtpStatus.SUCCESS,
    };
  }


  /**
     * The following case handles
     * Authentication failed
     * Fraud-list
     * Mobile Already mapped with a different account
     * OTP Verification failed
     * Verify Attempts exhausted
     */
  const {
    status: responseStatus,
    message,
    // editingAllowed,
    otp_status: otpStatus,
    verify_exceeded: verifyExceeded,
  } = response;

  let abortFlow = false;
  let errorType = VerifyMappingOtpError.OTHER;
  let prefixForErrorToLog = '';

  switch (true) {
    case otpStatus === 'fail':
      // Authentication Failure - can be caused to to forced edits
      errorType = VerifyMappingOtpError.AUTHENTICATION_FAILED;
      prefixForErrorToLog = prefixForMobileMappingErrorLogs.AUTHENTICATION_FAILED;
      break;
    case responseStatus === '7':
      // Number is fraudList
      errorType = VerifyMappingOtpError.FRAUD_LISTED;
      prefixForErrorToLog = prefixForMobileMappingErrorLogs.FRAUD_LIST;

      break;
    case responseStatus === '4':
      // Number already linked to other account
      errorType = VerifyMappingOtpError.NUMBER_ALREADY_LINKED;
      prefixForErrorToLog = prefixForMobileMappingErrorLogs.ALREADY_REGISTERED;
      break;
    case verifyExceeded === true:
      // Verification attempts exhausted
      errorType = VerifyMappingOtpError.EXHAUSTED_VERIFY_ATTEMPTS;
      prefixForErrorToLog = prefixForMobileMappingErrorLogs.RETRIES_EXHAUSTED;
      abortFlow = true;
      break;
    case responseStatus === 'fail':
      // Failed to verify OTP
      errorType = VerifyMappingOtpError.VERIFICATION_FAILED;
      prefixForErrorToLog = prefixForMobileMappingErrorLogs.OTP_VERIFICATION_FAILED;
      break;
    default:
      // Other errors
      break;
  }

  return {
    error: {
      abortFlow,
      type: errorType,
      messageToDisplay: message || standardErrorMessages.SOMETHING_WRONG,
      errorToLog: `${prefixForErrorToLog}:${VERIFY_MAPPING_OTP_API_ERROR}`,
    },
  };
};
