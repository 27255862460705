import { Action } from 'shared/store/types';
import actionTypes from './actionTypes';
import { FitcodeState } from 'shared/store/fitcode/types';


const defaultState: FitcodeState = {
  loading: false,
  isFetchingError: false,
  widgets: null,
};

const fitcode = (
  state = defaultState,
  action: Action,
) => {
  const { type, payload } = action;

  switch (type) {
    case actionTypes.FETCH_FITCODE_DATA_PROGRESS:
      return {
        ...state,
        loading: true,
        isNotFound: false,
        isFetchingError: false,
      };

    case actionTypes.FETCH_FITCODE_DATA_FAILED:
      return {
        ...state,
        loading: false,
        isNotFound: false,
        isFetchingError: true,
      };

    case actionTypes.FETCH_FITCODE_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        isNotFound: false,
        isFetchingError: false,
        widgets: payload,
      };
    }

      default:
      return state;
  }
};

export default fitcode;
