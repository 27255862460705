export const GET_MOBILE_MAPPING_STATUS_ERROR = 'Error in Get Mobile Mapping Status';
export const SEND_MAPPING_OTP_API_ERROR = 'Error in Send Mobile Mapping OTP';
export const VERIFY_MAPPING_OTP_API_ERROR = 'Error in Verify Mobile Mapping OTP';


export const prefixForMobileMappingErrorLogs = {
  ALREADY_REGISTERED: 'Already Registered',
  AUTHENTICATION_FAILED: 'Authentication Failed',
  FRAUD_LIST: 'Number in Fraud list',
  INVALID_LOGIN_METHOD: 'invalid login method',
  NETWORK_ERROR: 'Network Error',
  RETRIES_EXHAUSTED: 'retries exhausted',
  SUSPICIOUS: 'Suspicious',
  UNKNOWN: 'unknown error',
  OTP_VERIFICATION_FAILED: 'OTP verification failed',
};
