export const contentTypes = {
  URL_ENCODED: 'application/x-www-form-urlencoded',
};

export const DEFAULT_PROTO = 'https';
export const DEFAULT_DOMAIN = 'www.nykaa.com';
export const DEFAULT_DOMAIN_MEN = 'www.nykaaman.com';

export const customHeaders = {
  FORWARDED_PROTOTYPE: 'x-forwarded-proto',
  DOMAIN_NAME: 'x-domain-name',
  CANONICAL_URL: 'X-canonical-url',
  ORIGINAL_URL: 'X-Original-Url',
  BROWSER_URL: 'X-browser-url',
  CSRF_TOKEN: 'x-csrf-token',
};

export const COOKIE_MAX_AGE = 31536000000;

export enum HttpMethods {
  GET = 'get',
  POST = 'post',
}
