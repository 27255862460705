const actionTypes = {
  FETCH_INTRO_WIDGETS_PROGRESS: 'pro/introWidgetsStart',
  FETCH_INTRO_WIDGETS_SUCCESS: 'pro/introWidgetsSuccess',
  FETCH_INTRO_WIDGETS_ERROR: 'pro/introWidgetsError',
  FETCH_SECOND_FOLD_WIDGETS_START: 'pro/secondFoldWidgetsStart',
  FETCH_SECOND_FOLD_WIDGETS_SUCCESS: 'pro/secondFoldWidgetsSuccess',
  FETCH_SECOND_FOLD_WIDGETS_ERROR: 'pro/secondFoldWidgetsError',
};

export default actionTypes;
