import { appendQueryStringToUrl } from 'shared/helpers/utils/url';
import { History } from 'history';

import { pushEvent } from 'shared/helpers/utils/dataLayer';
import { pages } from 'shared/constants/urls';


export const getMobileVerifyStepFromHistory = (history: History): number => {
  let step = 0;
  if (history?.location?.state) {
    const { mobileVerifyStep } = history.location.state;

    if (mobileVerifyStep) {
      step = mobileVerifyStep;
    }
  }
  return step;
};


export const navigateUsersToMyProfilePage = () => {
  window.location.href = pages.MY_PROFILE_PAGE;
};


export const exitMobileVerificationFlow = () => {
  window.location.href = appendQueryStringToUrl(
    pages.MY_PROFILE_PAGE,
    'root=mobile_mapping_close'
  );
};


export const increaseMobileVerifyStepInHistory = (history: History): void => {
  const mobileVerifyStep = getMobileVerifyStepFromHistory(history);

  history.push({
    state: {
      mobileVerifyStep: mobileVerifyStep + 1,
    },
  });
};


interface PushMobileVerificationEvents {
  actionName: string; // can be converted to an enum
  data: any;
}

export const pushMobileVerificationEvents = ({
  actionName,
  data,
}: PushMobileVerificationEvents) => {
  pushEvent(
    actionName,
    {
      mobileVerification: {
        action: actionName,
        data,
      },
    },
  );
};
