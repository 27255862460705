import React from 'react';
import { styled } from '@nykaa/ui-components';
import loadableVisibility from 'react-loadable-visibility/loadable-components';
import AnimatedLoader from 'components/BelowTheFoldControl/AnimatedLoader';
import BelowTheFold from 'shared/components/BelowTheFoldControl';

const Loading = styled(AnimatedLoader)`
  height: 100px;
`;

const Loadable = loadableVisibility(() => import(/* webpackChunkName: "MyProfile" */ './Loadable'), {
  fallback: <Loading className="client" />,
});

const ServerOptimized = () => <BelowTheFold render={Loadable} loading={() => <Loading />} />;

export default ServerOptimized;
