import { RequestConfig } from '@nykaa/utils/network';
import { STORE_KEY_IN_QUERY } from 'constants/store';


export const handleStoreId = (storeId: string) => {
  RequestConfig.setStoreId(storeId || __PLATFORM__);
};


export const handleNavigationForStoreUpdate = (storeId: string) => {
  const queryParams = new URLSearchParams('');
  queryParams.append(STORE_KEY_IN_QUERY, storeId);

  const newNavForHomepage = `/?${queryParams.toString()}`;

  window.location.href = newNavForHomepage;
};


export const resetStore = () => {
  handleNavigationForStoreUpdate(__PLATFORM__);
};


export const setStore = (storeId: string) => {
  handleNavigationForStoreUpdate(storeId);
};
