import { combineReducers } from 'redux';
import reviewReducer from './review/reducer';
import auth from './auth/reducer';
import product from './product/reducer';
import app from './app/reducer';
import dataLayer from './dataLayer/reducer';


const createRootReducer = () => combineReducers({
  reviewReducer,
  auth,
  productPage: product,
  npsAppReducer: app,
  dataLayer,
});


export default createRootReducer;
