export const pages = {
  AUTH_PAGE: '/auth?ptype=auth',
  PDP_PAGE: '/{slug}?ptype=product&productId={id}',
  REVIEW_IMAGES_PAGE:
    '/{slug}/review-images/{id}?ptype=review-images&productId={childId}&eq=desktop',
  REVIEW_FORM_PAGE:
    '/rating-reviews?ptype=rateAndReview&parentId={id}&childId={childId}&pageSource=PD&trackingSource={trackingSource}',
  ALL_REVIEWS_PAGE: '/{slug}/reviews/{id}?skuId={childId}&ptype=reviews',
  SEARCH_RESULTS: '/search/result/',
  CHECKOUT_PAGE: '/shoppingBag',
  OFFER_PRODUCTS: '/offer-products',
  PRO_SIGN_UP: '/nykaa-pro-sign-up?ptype=nykaa-pro-sign-up',
  HOME: '/',
  MY_PROFILE_PAGE: '/myProfile',
  AUTH_PAGE_VERIFY: '/auth/verify?ptype=auth',
};

export const SEARCH_PROXY_PREFIX = '/nyk/aggregator-gludo';
