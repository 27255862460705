import React, { useEffect, useCallback } from 'react';

import { setRandomNumber, setABFlag } from './helper';


interface UserBucketProps {
  setRUN: () => void;
  configs: any[];
}

const UserBucket = ({ setRUN, configs } : UserBucketProps) => {
 const setRunAction = useCallback((run: number) => {
  setRUN(run);
  setABFlag(configs, run);
 }, [setRUN, configs]);

  useEffect(() => {
    setRandomNumber(setRunAction);
  }, [setRunAction]);
  return (
    <>
    </>
  );
};

export default UserBucket;
