
import statusCodes from 'constants/statusCodes';
import { DEFAULT_CONFIG_FLAGS } from 'shared/constants/configFlags';
import { AppState } from './types';


export const defaultClient = {
  device: 'WEB',
};

export const defaultState: AppState = {
  pageType: null,
  statusCode: statusCodes.SUCCESS,
  storeId: '',
  configFlagsFetched: false,
  configFlags: DEFAULT_CONFIG_FLAGS,
  run: 100,
  client: defaultClient,
  breadCrumb: [],
  isSliderOpen: false,
  showBreadcrumb: false,
};
