import React, { useState, useEffect } from 'react';
import styled from 'App/styled';
import BackArrowIcon from 'components/Icons/BackArrow';


const SHOW_BACK_TO_TOP_THRESHOLD = 200;


const BackToTopButton = styled.button`
  position: fixed;
  border: none;
  bottom: 50px;
  right: 10px;
  z-index: 5;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, .4);

  &::focus {
    outline: none;
  }

  svg {
    transform: rotate(90deg);
  }
`;


function BackToTop() {
  const [
    showBackToTop,
    setShowBackToTop,
  ] = useState(false);

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showBackToTop && window.pageYOffset > SHOW_BACK_TO_TOP_THRESHOLD) {
        setShowBackToTop(true);
      } else if (showBackToTop && window.pageYOffset <= SHOW_BACK_TO_TOP_THRESHOLD) {
        setShowBackToTop(false);
      }
    };
    window.addEventListener('scroll', checkScrollTop);
  });

  const handleBackToTop = (event: React.MouseEvent) => {
    event.preventDefault();

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  if (showBackToTop) {
    return (
      <BackToTopButton onClick={handleBackToTop}>
        <BackArrowIcon />
      </BackToTopButton>
    );
  }
  return null;
}

export default BackToTop;

