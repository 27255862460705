import { Action } from 'shared/store/types';
import actions from './actionTypes';
import { AuthState } from './types';


export const defaultState = {
  fetching: false,
  dataFetched: false,
  isFetchingError: false,
  fetchedOverNetwork: false,
  user: {
    cartItemsCount: 0,
    email: '',
    firstName: '',
    formKey: '',
    groupId: '',
    id: '',
    isLoyal: false,
    isPro: false,
    lastName: '',
    rewardPoints: 0,
    userName: '',
    wishlist: [],
  },
};

const authReducer = (
  state = defaultState,
  action: Action,
): AuthState => {
  const { type, payload } = action;

  switch (type) {
    case actions.FETCH_AUTH_DATA_PROGRESS:
      return {
        ...state,
        fetching: true,
        isFetchingError: false,
      };

    case actions.FETCH_AUTH_DATA_FAILED:
      return {
        ...state,
        fetching: false,
        isFetchingError: true,
      };

    case actions.FETCH_AUTH_DATA_SUCCESS:
      return {
        ...state,
        fetching: false,
        isFetchingError: false,
        dataFetched: true,
        fetchedOverNetwork: payload.fetchedOverNetwork,
        user: {
          ...payload.data, // FIXME: extract specific keys
        },
      };
    default:
      return state;
  }
};

export default authReducer;
