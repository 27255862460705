// helpers
import { pushEvent } from 'shared/helpers/utils/dataLayer';

// defs
import { ExperimentAllVariant } from '@nykaa/experiment-sdk/types';


const eventName = 'EXPERIMENT_INITIALIZED';

interface ExperimentInfo {
    uuid: string;
    experiments: ExperimentAllVariant;
}

export const pushExperiments = (experimentsInfo: ExperimentInfo) => {
  const { uuid, experiments } = experimentsInfo;
  const abConfigValues: ExperimentAllVariant[] = [];

  Object.keys(experiments).forEach(expId => {
    if (experiments[expId]
      && !!(experiments[expId]?.variant)) {
      abConfigValues.push({
        [expId]: experiments[expId],
      });
    }
  });
  pushEvent(eventName, { experimentUUID: uuid, ABV2: abConfigValues });
};
