export const DEFAULT_SNACKBAR = {
  id: '',
  msg: '',
  isError: false,
  status: false,
  isCart: false,
};
export const LAZYLOAD_COUNT = 5;
export const INFINITE_SCROLL_PAGE_THRESHOLD = 3;
export const NO_PRODUCT_MESSAGE = 'No More Products to Show';
export const LOAD_MORE_BUTTON_TEXT = 'View More products';
export const PRODUCT_LOADER_COUNT = 20;
export const PRODUCT_COUNT = 20;
export const CUSTOM_WISHLIST_CLASS = 'custom-wishlist-button';
export const CUSTOM_WISHLIST_CLASS_VIDEO_MODAL =
  'custom-wishlist-button-video-modal';
export const PHOTOS_FROM_CUSTOMERS = 'Photos From Customers';
export const DWEB_MULTICOUPON_EXP = 'AB_DWEB_MULTICOUPON';
export const AB_MULTI_MRP = 'AB_MULTI_MRP';
export const AB_FOOTER_LINK = 'AB_FOOTER_LINK';
export const AB_ZENDESK_CHAT = 'AB_ZENDESK_CHAT';
export const AB_PAGE_NOT_FOUND = 'AB_PAGE_NOT_FOUND';
