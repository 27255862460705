import { Action } from 'shared/store/types';
import actions from './actionTypes';
import { SearchSuggestion } from 'types/searchSuggestion';
import { SearchSuggestionsState } from './types';


export const defaultState: SearchSuggestionsState = {
  productSuggestions: [],
  otherSuggestions: [],
  trendingSearches: [],
  searchAutoSuggestions: [],
  searchField: '',
};

const searchSuggestionsReducer = (
  state = defaultState,
  action: Action,
) => {
  const { type, payload } = action;

  switch (type) {
    case actions.FETCH_SEARCH_SUGGESTION: {
      if (state.searchField === action.searchInput) {
        const productSuggestions = payload.filter((obj: SearchSuggestion) => obj.type === 'product');
        const otherSuggestions = payload.filter((obj: SearchSuggestion) => obj.type !== 'product');
        const searchAutoSuggestions = payload;
        return { ...state, productSuggestions, otherSuggestions, searchAutoSuggestions };
      }
      return state;
    }

    case actions.FETCH_TRENDING_SEARCHES:
      return { ...state, trendingSearches: payload };

    case actions.UPDATE_SEARCH_FIELD:
      return { ...state, searchField: payload };

    case actions.RESET_SEARCH:
      return {
        ...defaultState,
      };
    case actions.SHOW_SCREEN:
        return { ...state, showScreen: payload };
    default:
      return state;
  }
};

export default searchSuggestionsReducer;
