import { logger } from '@nykaa/logger';
import { Dispatch } from 'redux';
import { MobileVerificationStepName, MobileMappingStatus } from 'User/types/mobileVerification';
import { pages } from 'constants/urls';

import { mobileVerificationActions } from './../actionTypes';
import { fetchMobileMappingStats } from '../service';


export const getMobileMappingStatus = () => async (
  dispatch: Dispatch
) => {
  const transformedResponse = await fetchMobileMappingStats();

  /**
   * ? why 0?
   * * This is because this status check is the first step in the flow
   * * and the flow is a "0" indexed array of steps
   */
  const positionOfInterestInFlow = 0;
  if (transformedResponse.error) {
    const { error } = transformedResponse;
    logger.error(error.errorToLog);

    if (error.takeToHome) {
      // Take the user to homepage
      window.location.href = pages.HOME;
    } else {
      // dispatch action to take the user to error
      dispatch({
        type: mobileVerificationActions.REPLACE,
        payload: {
          positionOfInterest: positionOfInterestInFlow,
          stepData: {
            stepName: MobileVerificationStepName.ERROR,
          },
        },
      });
    }
  } else {
    // Success case dispatch action to take the user to Landing page
    const { status } = transformedResponse;
    if (status === MobileMappingStatus.VERIFIED) {
      dispatch({
        type: mobileVerificationActions.REPLACE,
        payload: {
          positionOfInterest: positionOfInterestInFlow,
          stepData: {
            stepName: MobileVerificationStepName.SUCCESS,
          },
        },
      });
    } else {
      // Handle the case where the user has to verify the mobile number
      const {
        isEditable,
        mobileNumber,
      } = transformedResponse;

      dispatch({
        type: mobileVerificationActions.SET_SUGGESTED_MOBILE,
        payload: mobileNumber,
      });

      dispatch({
        type: mobileVerificationActions.REPLACE,
        payload: {
          positionOfInterest: positionOfInterestInFlow,
          stepData: {
            stepName: MobileVerificationStepName.LANDING,
            isEditable,
            mobileNumber,
          },
        },
      });
      return transformedResponse;
    }
  }
};
