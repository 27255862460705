import { combineReducers } from 'redux';
import searchListing from '@nykaa/listing-shared/search/store/reducer';
import categoryListing from '@nykaa/listing-shared/category/store/reducer';
import variants from '@nykaa/product-card-shared/store/variants/reducer';
import reviewImages from '@nykaa/review-all-images-shared/store/reducer';
import appReducer from './app/reducer';
import navigation from '@nykaa/navigation/store/reducer';
import dataLayer from './dataLayer/reducer';
import product from './product/reducer';
import auth from './auth/reducer';
import RECOProducts from './recoProducts/reducer';
import searchSuggestions from './searchSuggestions/reducer';
import remoteConfig from './remoteConfig/reducer';
import productQna from './productQNA/reducer';
import priceReveal from '@nykaa/prices-revealed-shared/store/reducer';
import jsonLdData from '@nykaa/jsonld-shared/store/reducer';
import homeReducer from './home/reducer';
import fitcode from './fitcode/reducer';
import footerReducer from './footer/reducer';
import pro from './pro/reducer';
import VP from './visitorPrioritization/reducer';
import abExperiments from '@nykaa/experiment-sdk/store/reducer';
import proUserInfo from '@nykaa/pro-registration/store/reducer';
import getReviews from '@nykaa/review-filters-shared/store/reducer';
import wishlistReducer from '@nykaa/wishlist-shared/store/reducer';
import couponReducer from '@nykaa/coupon-shared/store/reducer';
import myProfile from '@nykaa/my-profile-shared/store/reducer';
import cmsReducer from './cms/reducer';
import authReducer from '@nykaa/auth-shared/store/reducers';
import dpMetaData from '@nykaa/seo/store/reducer';
import footerPopularLinksConfig from './footerPopularLinks';

const createRootReducer = () =>
  combineReducers({
    appReducer,
    auth,
    dataLayer,
    productPage: product,
    searchSuggestions,
    reviewImages,
    remoteConfig,
    productQna,
    variants,
    priceReveal,
    jsonLdData,
    homePage: homeReducer,
    fitcode,
    categoryListing,
    footer: footerReducer,
    searchListingPage: searchListing,
    pro,
    VP,
    navigation,
    abExperiments,
    proUserInfo,
    getReviews,
    RECOProducts,
    wishlistReducer,
    couponReducer,
    myProfile,
    cmsReducer,
    authReducer,
    dpMetaData,
    footerPopularLinksConfig,
  });

export default createRootReducer;
