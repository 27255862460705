import { Action } from 'shared/store/types';
import RecoEvents from './actionTypes';
import { RecommendationsState } from './types';


const defaultState: RecommendationsState = {
  loading: false,
  isNotFound: false,
  isFetchingError: false,
  CAV: [],
  CAB: [],
  recoDataLoaded: false,
};


const RECOProducts = (
  state = defaultState,
  action: Action,
) => {
  const { type, payload } = action;
  switch (type) {
    case RecoEvents.FETCH_RECO_DATA_PROGRESS:
      return {
        ...state,
        loading: true,
        isNotFound: false,
        isFetchingError: false,
        recoDataLoaded: false,
      };

      case RecoEvents.FETCH_RECO_DATA_SUCCESS:
      return {
        ...state,
        CAB: payload?.cabTransformedProduct,
        CAV: payload?.cavTransformedProduct,
        loading: false,
        isNotFound: false,
        isFetchingError: false,
        recoDataLoaded: true,
      };

      case RecoEvents.FETCH_RECO_DATA_NOT_FOUND:
        return {
          ...state,
          loading: false,
          isNotFound: true,
          isFetchingError: false,
          recoDataLoaded: false,
        };

        case RecoEvents.FETCH_RECO_DATA_FAILED:
        return {
          ...state,
          loading: false,
          isNotFound: false,
          isFetchingError: true,
          recoDataLoaded: false,
        };

    default:
      return state;
  }
};


export default RECOProducts;
