import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Frontload } from 'react-frontload';

import { initLogger, logger } from '@nykaa/logger';
import { configureStore } from 'shared/store';
import App from 'shared/App';
import Nps from 'shared/Nps';
import User from 'User';

import polyfillPromise from 'client/polyfills';
import { addRequestInterceptor } from '@nykaa/utils/network';
import { csrfHeader } from '@nykaa/utils/network/interceptor/csrf';
import { storeParam } from '@nykaa/utils/network/interceptor/store';
import { catalogTagFilter } from '@nykaa/utils/network/interceptor/catalogTagFIlter';
import { npsStore } from 'Nps/store';
import { configureUserStore } from 'User/store';
import { VP } from 'api/interceptor/visitorPrioritization';
import { getLogDir } from 'server/helpers';

const {
  __PRELOADED_STATE__: initialState,
  __NPS_PRELOADED_STATE__: npsInitialState,
  __USER_PRELOADED_STATE__: userInitialState,
} = window;

const store = window.store || configureStore({ initialState });
const storeData = window.store || npsStore({ initialState: npsInitialState });
const userStore =
  window.store || configureUserStore({ initialState: userInitialState });
const { sentrySampleRate, newRelicSampleRate } =
  initialState.remoteConfig.configs;
const sentryOptions = { sampleRate: sentrySampleRate };
const newRelicOptions = { sampleRate: newRelicSampleRate };
initLogger(getLogDir(), sentryOptions, newRelicOptions);

addRequestInterceptor(csrfHeader);
addRequestInterceptor(storeParam);
addRequestInterceptor(catalogTagFilter);
addRequestInterceptor((config) => VP(config, store));

polyfillPromise.then(() => {
  loadableReady(() => {
    hydrate(
      <Router>
        <Switch>
          <Route path="/nps/review">
            {/**
             * FIXME:  Move this to inside the NPS sub app
             * and pass the store to Nps as
             * <Nps store={storeData} />
             */}
            <Provider store={storeData}>
              <Frontload>
                <Nps />
              </Frontload>
            </Provider>
          </Route>
          <Route path="/user">
            <User store={userStore} />
          </Route>
          <Route path="*">
            <Provider store={store}>
              <Frontload>
                <App />
              </Frontload>
            </Provider>
          </Route>
        </Switch>
      </Router>,
      document.getElementById('app')
    );
  });
});

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept();
  }

  if (!window.store) {
    window.store = store;
  }
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    const cid = process.env.GAMOOGA_TRACKING_ID;

    navigator.serviceWorker
      .register(`/sw.js?cid=${cid}`)
      .then(() => {
        // The above then resolves with "registration" object
        // but we chose to ignore it for the moment
      })
      .catch((registrationError) => {
        logger.info(registrationError, 'Error installing service worker file');
      });
  });

  const pwaPromptListener = (promptEvent: Event) => {
    promptEvent.preventDefault();
  };

  window.addEventListener('beforeinstallprompt', pwaPromptListener);
}
