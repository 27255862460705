import { Action } from 'shared/store/types';
import actionTypes from './actionTypes';
import { QNAState } from './types';


const defaultState: QNAState = {
  error: false,
  QNA: [],
};

const QNAReducer = (state = defaultState, action: Action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET:
      return {
        error: false,
        QNA: payload,
      };

    case actionTypes.SET_ERROR:
      return {
        ...defaultState,
        error: true,
      };

    default:
      return state;
  }
};

export default QNAReducer;
