import { Action } from 'shared/store/types';
import actionTypes from './actionTypes';
import { FooterState } from './types';

const defaultState: FooterState = {
  priceList: {},
  aboutUs: {},
  categories: {},
};

const footer = (state = defaultState, action: Action): any => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.FETCH_PRICE_LIST: {
      return {
        ...state,
        priceList: payload,
      };
    }
    case actionTypes.FETCH_FOOTER_CATEGORIES: {
      return {
        ...state,
        categories: payload,
      };
    }
    case actionTypes.FETCH_ABOUT_US: {
      return {
        ...state,
        aboutUs: payload,
      };
    }
    case actionTypes.FETCH_DYNAMIC_FOOTER_LINKS: {
      return {
        ...state,
        relatedLinks: payload,
      };
    }
    default:
      return state;
  }
};

export default footer;
