import createRootReducer from './rootReducer';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose, Store } from 'redux';


interface StoreParams {
  initialState?: { [key: string]: object };
};


let store: Store;

export const configureStore = ({
  initialState,
}: StoreParams) => {
  const devTools =
    typeof window !== 'undefined' &&
    typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ actionsBlacklist: [] });

  const composeEnhancers = devTools || compose;

  store = createStore(
    createRootReducer(),
    initialState,
    composeEnhancers(
      applyMiddleware(
        ...[
          thunk,
        ]
      )
    )
  );

  return store;
};

export const getStore = () => store;
