const polyfills = [];

const isSupportsES6 = (function () {
  try {
    new Function('(a = 0) => a');
    return true;
  } catch (err) {
    return false;
  }
})();

if (isSupportsES6 === false || !window.fetch || !window.URLSearchParams) {
  polyfills.push(import(/* webpackChunkName: "polyfills" */ 'core-js/stable'));
}

if (
  !('IntersectionObserver' in window) ||
  !('IntersectionObserverEntry' in window) ||
  !('intersectionRatio' in window.IntersectionObserverEntry.prototype)
) {
  // load polyfill now
  polyfills.push(
    import(/* webpackChunkName: "polyfills-IO" */ 'intersection-observer')
  );
}

const polyfillRequestIdleCallback = () => {
  window.requestIdleCallback =
    window.requestIdleCallback ||
    function (cb) {
      return setTimeout(function () {
        const start = Date.now();
        cb({
          didTimeout: false,
          timeRemaining: function () {
            return Math.max(0, 50 - (Date.now() - start));
          },
        });
      }, 1);
    };

  window.cancelIdleCallback =
    window.cancelIdleCallback ||
    function (id) {
      clearTimeout(id);
    };
};

if (window.requestIdleCallback === undefined) {
  polyfillRequestIdleCallback();
}

function getIDBGetAllPolyfill() {
  // support for browsers chrome < 48
  const IDBObjectStore =
    window.IDBObjectStore ||
    window.webkitIDBObjectStore ||
    window.mozIDBObjectStore ||
    window.msIDBObjectStore;
  const IDBIndex =
    window.IDBIndex ||
    window.webkitIDBIndex ||
    window.mozIDBIndex ||
    window.msIDBIndex;

  if (
    typeof IDBObjectStore === 'undefined' ||
    typeof IDBIndex === 'undefined'
  ) {
    return false;
  }

  const isIDBGetAllKeysSupported =
    IDBObjectStore.prototype.getAll !== undefined &&
    IDBIndex.prototype.getAll !== undefined &&
    IDBObjectStore.prototype.getAllKeys !== undefined &&
    IDBIndex.prototype.getAllKeys !== undefined;

  return isIDBGetAllKeysSupported === false;
}

if (getIDBGetAllPolyfill()) {
  polyfills.push(
    import(
      /* webpackChunkName: "polyfills-idb-getall" */ 'indexeddb-getall-shim'
    )
  );
}

export default Promise.all(polyfills);
