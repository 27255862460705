import { useSelector } from 'react-redux';
import { State } from 'shared/store/types';


export const useIsLoggedIn = (): boolean => {
  const isLoggedIn = useSelector((state: State) => {
    const { auth } = state;

    return (auth.dataFetched && !!auth.user.email);
  });

  return isLoggedIn;
};

export const useUserData = () => {
  const user = useSelector((state: State) => {
    const { auth } = state;

    return auth.user;
  });

  return user;
};
