import React from 'react';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';

import { State } from 'shared/store/types';


const Loadable = loadable(
  () => import(/* webpackChunkName: "vp" */'./Loadable'),
);

const Component = (props: any) => {
  const {
    vpEnabled,
  } = useSelector((state: State) => state.remoteConfig.switches);
  if (vpEnabled) {
    return <Loadable {...props} />;
  }
  return null;
};

export default Component;
