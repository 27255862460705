import { Action } from 'shared/store/types';
import actionTypes from './actionTypes';
import { ProductState } from './types';


const defaultState: ProductState = {
  products: {},
  selectedProduct: null,
  productLoading: false, // FIXME: Store only the data that is relevant
  isFetchingError: false,
};

const productReducer = (
  state = defaultState,
  action: Action,
) => {
  const { type, payload, productId } = action;
  switch (type) {
    case actionTypes.FETCH_PRODUCT_DATA_PROGRESS:
      return {
        ...state,
        productLoading: true,
        isNotFound: false,
        isFetchingError: false,
      };

    case actionTypes.FETCH_PRODUCT_DATA_FAILED:
      return {
        ...state,
        productLoading: false,
        isNotFound: false,
        isFetchingError: true,
      };

    case actionTypes.FETCH_PRODUCT_DATA_SUCCESS: {
      let productList = {};
      if (state.products && (Object.keys(state.products).length > 0)) {
        productList = { ...state.products, [payload.id]: payload };
      };
      const productData = Object.keys(productList);
      let selectedProduct = payload;
      if (productData && (productData.length > 0)) {
        if (productId) {
          selectedProduct = productList[productId];
        } else {
          selectedProduct = productList[productData[0]];
        }
      }
      return {
        ...state,
        productLoading: false,
        isNotFound: false,
        products: { ...state.products, [payload.id]: payload },
        selectedProduct: selectedProduct,
        isFetchingError: false,
      };
    }
    case actionTypes.UPDATE_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: payload,
      };
    default:
      return state;
  }
};
export default productReducer;
