import { USER_DATA_STORAGE_KEY, PRO_COOKIE_KEY } from 'constants/cookies';
import { setCookie } from 'shared/helpers/utils/cookie';
import { ApiHelper } from '@nykaa/utils/network';
import { ROUTES, getUrl } from 'shared/api/urls';
import { contentTypes } from 'constants/requestHeaders';
import { logger } from '@nykaa/logger';
import { logErrors } from 'shared/helpers/utils/logErrors';
import { transformAuthData } from './transformer';
import {
  getAuthFromStorage,
  storeAuthInStorage,
  removeAuthFromStorage,
} from './helper';
import { pushEvent } from '@nykaa/data-layer/utils';
import { events } from '@nykaa/data-layer/constants';
import { UNAUTHORIZE_ERROR_CODE } from 'shared/constants';

const CHAT_PARAMS = {
  mode: 'popout',
  deviceType: 'desktop',
};

export const getAuth = async () => {
  const authDataInStorage = getAuthFromStorage();
  if (authDataInStorage) {
    const { data: { result } = { result: null } } = authDataInStorage;
    if (result) {
      try {
        const transformedReactConfig = transformAuthData(result);

        return Promise.resolve({
          fetchedOverNetwork: false,
          data: transformedReactConfig,
        });
      } catch (err) {
        logger.error(err, 'Error Transforming react config from storage');
      }
    }
  }
  // The below code is executed if extracting data from storage is not successful
  const url = getUrl(ROUTES.AUTH);
  try {
    const response = await ApiHelper(`${url}`, 'post');
    const { data, status, statusText } = response;

    storeAuthInStorage({
      data: data,
      status: status,
      statusText: statusText,
    });
    const {
      data: { result },
    } = response;
    if (!result) {
      return Promise.reject({
        message: '"response" not found in api -> data',
      });
    }
    const transformedAuthData = transformAuthData(result);
    const { groupId, id, isPro } = transformedAuthData;
    // TODO: set auth data in session
    setCookie(
      USER_DATA_STORAGE_KEY,
      JSON.stringify({
        id,
        nykaa_pro: isPro,
        group_id: groupId,
      }),
      1
    );
    setCookie(PRO_COOKIE_KEY, isPro ? true : false, 1);

    return Promise.resolve({
      fetchedOverNetwork: true,
      data: transformedAuthData,
    });
  } catch (err) {
    // ? for axios: http status < 200 || status >= 300 => error
    // * https://github.com/axios/axios/issues/472#issuecomment-252584718
    const customMessage = 'Error in react config api';
    logErrors(err, customMessage);
    throw err;
  }
};

export const logOut = async () => {
  const url = getUrl(ROUTES.LOGOUT);

  try {
    const { data } = await ApiHelper(`${url}`, 'get', undefined, {
      'Content-Type': contentTypes.URL_ENCODED,
    });
    // ! When user logs out we need to remove config from storage
    removeAuthFromStorage();
    pushEvent(events.USER_LOGOUT, { logout_type: 'logout' });

    // update in localStorage
    if (!data) {
      return Promise.reject({
        message: '"data" not found in api',
      });
    }

    return true;
  } catch (err) {
    const customMessage = 'Error in logout api';
    logErrors(err, customMessage);
    // ? for axios: http status < 200 || status >= 300 => error
    // * https://github.com/axios/axios/issues/472#issuecomment-252584718
    throw err;
  }
};

interface LoginParams {
  id: string;
  platform: string;
  password: string;
  smart: string;
}

export const doLogin = async (params: LoginParams) => {
  // TODO: need to remove React Storage.
  const { id, platform, password, smart } = params;
  const url = getUrl(ROUTES.LOGIN);
  const emailCheck = params.id.search('@');
  const formData = new FormData();
  if (emailCheck > 0) {
    formData.append('login[username]', id);
  } else {
    formData.append('login[mobile_number]', id);
  }
  formData.append('login[password]', password);
  formData.append('login[smart]', smart);
  formData.append('login[platform]', platform);

  try {
    removeAuthFromStorage();
    const { data } = await ApiHelper(`${url}`, 'post', formData, {
      'Content-Type': contentTypes.URL_ENCODED,
    });
    if (!data) {
      return Promise.reject('Login data not found');
    }

    return data;
  } catch (e) {
    const customMessage = 'Error Fetching Login API';
    logErrors(e, customMessage);
    throw e;
  }
};

export const logOutAll = async () => {
  const url = getUrl(ROUTES.LOGOUT_ALL);
  const formData = new FormData();
  formData.append('source', 'react');
  try {
    const { data } = await ApiHelper(`${url}`, 'post', formData);
    // ! When user logs out we need to remove config from storage
    removeAuthFromStorage();
    pushEvent(events.USER_LOGOUT, {
      logout_type: 'logoutAll',
    });

    // update in localStorage
    if (!data) {
      return Promise.reject({
        message: '"data" not found in api',
      });
    }

    return true;
  } catch (err) {
    const customMessage = 'Error in logout all session api';
    logErrors(err, customMessage);
    // ? for axios: http status < 200 || status >= 300 => error
    // * https://github.com/axios/axios/issues/472#issuecomment-252584718
    throw err;
  }
};

export const getHash = async () => {
  const url = `${getUrl(ROUTES.USER_CHAT_HASH)}`;
  try {
    const responseData = await ApiHelper(`${url}`, 'post', CHAT_PARAMS);
    const response = responseData?.data;
    const hash = response?.data?.hash || '';
    const unqTxnId = response?.data?.unqTxnId || '';
    const phoneNo = response?.data?.phoneNo || '';
    return { hash, unqTxnId, phoneNo };
  } catch (err) {
    const customMessage = 'Error in getting Verloop hash';
    logErrors(err, customMessage);
    return;
  }
};

export const getReactConfig = async () => {
  const url = getUrl(ROUTES.AUTH);
  try {
    const response = await ApiHelper(`${url}`, 'post');
    const { data, status, statusText } = response;

    storeAuthInStorage({
      data: data,
      status: status,
      statusText: statusText,
    });
    const {
      data: { result },
    } = response;
    if (!result) {
      return Promise.reject({
        message: `${UNAUTHORIZE_ERROR_CODE} "response" not found in api -> data`,
      });
    }
    const transformedAuthData = transformAuthData(result);
    const { groupId, id, isPro } = transformedAuthData;
    // TODO: set auth data in session
    setCookie(
      USER_DATA_STORAGE_KEY,
      JSON.stringify({
        id,
        nykaa_pro: isPro,
        group_id: groupId,
      }),
      1
    );
    setCookie(PRO_COOKIE_KEY, isPro ? true : false, 1);

    return Promise.resolve({
      fetchedOverNetwork: true,
      data: transformedAuthData,
    });
  } catch (err) {
    // ? for axios: http status < 200 || status >= 300 => error
    // * https://github.com/axios/axios/issues/472#issuecomment-252584718
    const customMessage = 'Error in react config api';
    logErrors(err, customMessage);
    throw err;
  }
};
