import { Client, Device } from 'shared/types';

export const PIN_CODE_FOR_DELIVERY = 'pinCodeDel';

export enum userSessionTypes {
  GUEST = 'guest',
  LOGGED_IN = 'logged_in',
}

export enum platformNames {
  NYKAA = 'nykaa',
  MEN = 'men',
}

export enum standardErrorMessages {
  SOMETHING_WRONG = 'Something went wrong!',
  OOPS = 'Oops! Something went wrong...',
}

export const PRODUCT_ADDED_TO_CART = 'Product Added to Cart!';
export const PRICE_RANGE_LISTING_ERROR =
  '0 items found for selected range. The price filter has been reset';
export const PRICE_RANGE_LISTING_DISMISS = 'Dismiss';

export enum deviceTypes {
  M_SITE = 'msite',
  M_WEB = 'mweb',
  D_SITE = 'website',
  DESKTOP = 'desktop',
}

export enum variantTypes {
  SIZE = 'SIZE',
  SHADE = 'SHADE',
  SIMPLE = 'SIMPLE',
  BUNDLE = 'BUNDLE',
}

export const SIZE_CHART = 'Size Chart';

export const CURRENCY = 'INR';

export enum BOOLEAN_STRINGS {
  FALSE = 'false',
}
export const EU_PRIVACY = 'euPrivacy';
export const CONTINENT_CODE = 'continentCode';
export const X_CONTINENT_CODE = 'x-continent-code';

export const COUNTRY_CODE = 'countryCode';
export const X_COUNTRY_CODE = 'x-country-code';

export const REGION_HEADER = 'cloudfront-viewer-country-region';
export const REGION_HEADER_COUNTRY = 'cloudfront-viewer-country';

export const PRODUCT_ADDED_TO_WISHLIST = 'Product has been added to Wishlist.';
export const PRODUCT_REMOVED_FORM_WISHLIST =
  'Product has been removed from Wishlist.';

export const EU_CONTINENT_CODE = 'eu';

export const defaultClient: Client = {
  device: Device.WEB,
};

export enum ELEMENT_SIZE {
  SMALL = 'small',
  MEDIUM = 'medium',
}

export const NAV_BRAND_QUERY_SANITIZATION = /[^a-zA-Z0-9\s]/g;

export const NO_QUERY_EXPANSION_TERM = 'no_query_expansion_term';
export const NO_QUERY_EXPANSION_TYPE = 'no_query_expansion_type';
export const artImagePrefix =
  'https://www.nykaa.com/media/categoryInfo/art_banner_image';
export const defaultArtVideoImagePrefix =
  'https://www.nykaa.com/media/categoryInfo/art_banner_video_image';

export const HOMEPAGE_PAGE_TYPE = 'desktop-homepage';
export const CART_QUERY = 'showCart';

export const UNAUTHORIZE_ERROR_CODE = 401;
export const ADD_TO_CART_ERR_MESSAGE = 'Error in Add to cart';
export const DYNAMIC_FOOTER_LINK_API_ERR_MESSAGE =
  'Error in Fetch Dynamic footer Links api';
