import { ApiHelper } from '@nykaa/utils/network';
import { logger } from '@nykaa/logger';
import { HttpMethods } from 'constants/requestHeaders';
import { getUrl, ROUTES } from 'shared/api/urls';


export const postDeviceIdentity = async (
  deviceInfoString: string,
) => {
  const requestData = { deviceInfo: deviceInfoString };
  const endPointForDeviceTracking = getUrl(ROUTES.DEVICE_TRACKING_SERVICE);

  try {
    await ApiHelper(
      endPointForDeviceTracking,
      HttpMethods.POST,
      requestData,
    );
  } catch (err) {
    logger.error(err, 'Error sending device info');
  }
};
