const reviewDataEvents = {
  FETCH_REVIEWS_START: 'reviews/fetch_data_progress',
  FETCH_REVIEWS_SUCCESS: 'reviews/fetch_data_success',
  FETCH_REVIEWS_ERROR: 'reviews/fetch_data_failure',
  SUBMIT_REVIEW_START: 'review/submit_review_progress',
  SUBMIT_REVIEW_SUCCESS: 'review/submit_review_success',
  SUBMIT_REVIEW_ERROR: 'review/submit_review_failure',
  CURRENT_REVIEW: 'review/selected_review',
  UPDATE_REVIEWS: 'reviews/update_reviews',
  UPDATE_REVIEW_IMAGES: 'review/update_review_images',
};
export default reviewDataEvents;
