import { getErrorString } from 'store/dataLayer/helpers';
import { pageTypes } from 'shared/constants/dataLayer';
import { Device } from 'types';

export const getCMSApi = () => {
  const cmsApiHost = process.env.CMS_API_INTERNAL;
  const apiHost = `${cmsApiHost}/cms-pageapi/cmspage/cms-service/slug`;
  return apiHost;
};

const ESCAPE_LOOKUP: { [match: string]: string } = {
  '&': '\\u0026',
  '>': '\\u003e',
  '<': '\\u003c',
  '\u2028': '\\u2028',
  '\u2029': '\\u2029',
};

const ESCAPE_REGEX = /[&><\u2028\u2029]/g;

export function htmlEscapeJsonString(str: string): string {
  return str.replace(ESCAPE_REGEX, (match) => ESCAPE_LOOKUP[match]);
}

export const getInlineScriptSource = (jsonData: string) => {
  const data = JSON.stringify(jsonData);
  return htmlEscapeJsonString(data);
};

export const getDataLayer = (
  errorCode: number | null,
  brand: string = '',
  device: Device
) => {
  if (errorCode && errorCode > 200) {
    return {
      pageName: getErrorString(errorCode),
      pageType: pageTypes.CMS_PAGE,
      cmsSource: device,
    };
  }

  return {
    pageName: `Msite: ${brand}`,
    pageType: pageTypes.CMS_PAGE,
    cmsSource: device,
  };
};
