import { INDIA_ISO_CODE } from 'shared/constants/internationalShipping';
import { randomNumberGenerator } from 'shared/helpers/utils/Numbers';
import { setCookie, getCookie } from 'shared/helpers/utils/cookie';
import { RUN, RULE_ID } from 'constants/cookies';
import { pushEvent } from '@nykaa/data-layer/utils';

export const getExpiryMins = (expireInMinutes = 30) => expireInMinutes * 60 * 1000;

export const getRandomNumberFromCookie = () => getCookie(RUN);

export const getRuleIdCookie = () => getCookie(RULE_ID);

export const setRandomNumberToCookie = (run: number, expiryMins = 43200) => {
  setCookie(RUN, run, getExpiryMins(expiryMins));
};

export const setRuleIdToCookie = (ruleId: string, expiryMins: number) => {
  setCookie(RULE_ID, ruleId, getExpiryMins(expiryMins));
};

export const getUserNumber = (ruleId: any, expiryTime: number, assignIfNotExist = true) => {
  let changeRuleId = false;
  const ruleIDFromCookie = getRuleIdCookie();
  if (!ruleIDFromCookie) {
    setRuleIdToCookie(ruleId, expiryTime);
  } else {
    changeRuleId = ruleId !== getRuleIdCookie();
  }
  let randomNumber = getRandomNumberFromCookie();
  if (changeRuleId || (assignIfNotExist && !randomNumber)) {
    randomNumber = randomNumberGenerator();
    setRandomNumberToCookie(randomNumber, expiryTime);
    if (changeRuleId) {
      setRuleIdToCookie(ruleId, expiryTime);
    }
  }
  return randomNumber;
};

export const checkIsIndianUser = (regionHeaderCountry: string) => {
  if (regionHeaderCountry && regionHeaderCountry !== INDIA_ISO_CODE) {
    return false;
  }
  return true;
};

export const setSuperProperty = () => {
  pushEvent('appLaunched');
};
