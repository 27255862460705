import React from 'react';
import loadable from '@loadable/component';
import { ProductLoader } from 'components/ProductLoader';

export default loadable(
  () => import(/* webpackChunkName: "MyCoupons" */ '../../layouts/MyCoupons'),
  {
    fallback: <ProductLoader />,
  }
);
