import { Dispatch } from 'redux';

import { getBreadCrumbs, getConfigFlags } from './service';
import actions from './actionTypes';

export const fetchConfigFlags = () => (dispatch: Dispatch) =>
  getConfigFlags().then((configFlags) => {
    dispatch({
      type: actions.CONFIG_FLAGS,
      payload: configFlags,
    });
  });

export const setRUN = (run: number) => (dispatch: Dispatch) => {
  dispatch({
    type: actions.SET_RUN,
    payload: run,
  });
};

export const fetchBreadCrumbs = (id: string) => (dispatch: Dispatch) =>
  getBreadCrumbs(id).then((res) => {
    dispatch({
      type: actions.SET_BREADCRUMB,
      payload: res,
    });
    return res;
  });

export const toggleSlider = () => (dispatch: Dispatch) =>
  dispatch({
    type: actions.TOGGLE_SLIDER,
  });

export const updatePageStatusCode =
  (status: number) => (dispatch: Dispatch) => {
    dispatch({
      type: actions.APP_REDUCER_STATUS_CODE,
      payload: status,
    });
  };
